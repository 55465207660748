import { Heading } from '@hexa-ui/components';
import { styled } from 'theme';

export const HeaderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '24px 0',
  justifyContent: 'space-between',
  gap: '$4',
});

export const HeaderRowContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  fontSize: '32px',
});

export const HeaderTitle = styled(Heading, {
  margin: 'auto 0 0',
});

export const HeaderActions = styled('div', {
  marginLeft: 'auto',
  display: 'flex',
  gap: '$4',
});
