import { Error404 as HexaError404 } from '@hexa-ui/components';
import { ComponentProps } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Container, Content } from './404.styles';

interface Error404Props {
  goBackCallback?: ComponentProps<typeof HexaError404>['buttonClickHandler'];
}

export function Error404({ goBackCallback }: Error404Props): JSX.Element {
  const { formatMessage } = useIntl();

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container data-testid="error-404-page">
      <Content>
        <HexaError404
          buttonClickHandler={goBackCallback ?? goBack}
          headerText={formatMessage({ id: 'ERROR_COMPONENTS.NOT_FOUND_HEADER_TEXT' })}
          subHeaderText={formatMessage({ id: 'ERROR_COMPONENTS.NOT_FOUND_SUBHEADER_TEXT' })}
          buttonText={formatMessage({ id: 'ERROR_COMPONENTS.NOT_FOUND_HOME_BTN' })}
        />
      </Content>
    </Container>
  );
}
