import { RootState, store } from 'app/store';
import { Countries } from 'domains/Countries';
import {
  EndpointsToLog,
  LogUpdateStore,
  LogUpdateVendorStores,
  resetLog as resetLogAction,
} from 'features/log/log';
import { ActionType, useAuditLog } from 'hooks/useAuditLog';
import { memo, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const RESET_LOG_TIMEOUT = 800;

function Logger() {
  const auditLog = useAuditLog();

  const logData = useSelector((state: RootState) => state.log);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  const logAssociateDissociate = (
    { vendorId, vendorName, stores }: LogUpdateVendorStores['data'],
    country: Countries
  ) => {
    stores?.forEach(({ id: storeId, associated }) => {
      auditLog({
        country,
        storeId,
        metadata: {
          action: associated ? ActionType.ASSOCIATE : ActionType.DISSOCIATE,
          vendor: {
            id: vendorId,
            name: vendorName,
          },
        },
      });
    });
  };

  const logEdit = ({ storeId, fields }: LogUpdateStore['data'], country: Countries) => {
    auditLog({
      country,
      storeId,
      metadata: {
        action: ActionType.EDIT,
        fields,
      },
    });
  };

  const resetLog = () => {
    if (timeoutId.current) clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(() => {
      store.dispatch(resetLogAction());
    }, RESET_LOG_TIMEOUT);
  };

  useEffect(() => {
    const { endpoint, data, country } = logData;
    if (endpoint && data) {
      if (endpoint === EndpointsToLog.updateVendorStores) {
        logAssociateDissociate(data, country);
      }
      if (endpoint === EndpointsToLog.updateStore) {
        logEdit(data, country);
      }

      resetLog();
    }
  }, [logData]);

  return null;
}

export default memo(Logger, () => true);
