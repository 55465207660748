import { Card, IconButton, Paragraph } from '@hexa-ui/components';
import { MoreHorizontal } from '@hexa-ui/icons';
import { styled } from 'theme';

export const Container = styled('div', {
  position: 'relative',
  display: 'flex',
  height: '$8',
  width: '$8',
});

export const OptionsButton = styled(IconButton, {
  zIndex: 1,
});

export const DropdownContainer = styled(Card, {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  position: 'absolute',
  right: '0',
  zIndex: 2,
  variants: {
    isNearToBottom: {
      true: {
        bottom: '$10',
      },
      false: {
        top: '$10',
      },
    },
  },
});

export const DropdownItem = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$2',
  padding: '0 $4',
  height: '56px',
  cursor: 'pointer',

  '&:hover': {
    backgroundColor: '$neutral20',
  },
});

export const DropdownText = styled(Paragraph, {
  whiteSpace: 'nowrap',
});

export const DropdownIcon = () => <MoreHorizontal />;
