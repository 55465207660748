import { Toggle } from '@hexa-ui/components';
import { Field, FieldProps } from 'formik';
import omit from 'lodash/omit';
import { ComponentProps } from 'react';
import { useIntl } from 'react-intl';
import { Container, Label } from './FormikToggle.styles';

export type FormikToggleProps = ComponentProps<typeof Toggle> & {
  name: string;
  showOnOffLabel?: boolean;
  onChange?: (value: boolean) => void;
};

function FormikToggle({
  name,
  onChange,
  showOnOffLabel,
  ...toggleProps
}: FormikToggleProps): JSX.Element {
  const { formatMessage } = useIntl();

  const ACTIVE_LABEL = formatMessage({ id: 'COMPONENTS.TOGGLE_ACTIVE_LABEL' });
  const INACTIVE_LABEL = formatMessage({ id: 'COMPONENTS.TOGGLE_INACTIVE_LABEL' });

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const fieldWithoutOnChange = omit(field, 'onChange');
        const textToDisplay = field.value ? ACTIVE_LABEL : INACTIVE_LABEL;

        return (
          <Container>
            <Toggle
              id={name}
              {...toggleProps}
              {...fieldWithoutOnChange}
              checked={field.value}
              onCheckedChange={(changedValue) => {
                onChange?.(changedValue);
                form.setFieldValue(field.name, changedValue);
              }}
            />
            {showOnOffLabel && (
              <Label htmlFor={toggleProps.id ?? name} isDisabled={!!toggleProps.disabled}>
                {textToDisplay}
              </Label>
            )}
          </Container>
        );
      }}
    </Field>
  );
}

export default FormikToggle;
