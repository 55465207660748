import { formatRequestorInfo } from '../../../commonFormatters';
import { ActivityItemType, IntlConfigType } from '../../../format.types';

type EditNameParams = {
  intlConfig: IntlConfigType;
  storeName: string;
  newStoreName: string;
  userName: string;
  creationDate: Date;
  userId: string;
};

export const editName = ({
  intlConfig,
  storeName,
  newStoreName,
  userName,
  creationDate,
  userId,
}: EditNameParams): ActivityItemType => {
  const { formatMessage } = intlConfig.intl;

  return {
    title: formatMessage(
      { id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_EDIT_NAME_TITLE' },
      { storeName }
    ),
    description: formatMessage(
      { id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_EDIT_NAME_DESCRIPTION' },
      { storeName, newStoreName }
    ),
    requestorInfo: formatRequestorInfo(userName, creationDate, intlConfig),
    userId,
  };
};
