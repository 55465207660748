import { styled } from 'theme';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$2',
  '& > button[data-state="checked"]': {
    backgroundColor: '$interfaceDatavis9',
    '&:hover': {
      backgroundColor: '$semanticSuccessBasis',
    },
  },
});

export const Label = styled('label', {
  cursor: 'pointer',
  fontSize: '$3',
  variants: {
    isDisabled: {
      true: {
        opacity: 0.6,
        cursor: 'default',
      },
    },
  },
});
