import { Paragraph, SkeletonLoader } from '@hexa-ui/components';
import CopyButton from 'components/CopyButton/CopyButton';
import { useEffect, useRef, useState } from 'react';
import {
  ItemListContainer,
  ItemContainer,
  ItemIcon,
  ItemInfo,
  ItemRequestor,
  LoadMoreError,
  EmptyStateContainer,
} from './RecentActivity.styles';
import { useIntl } from 'react-intl';
import ErrorBox from 'assets/error-box.svg';
import { ActivityItemType } from '../format/format.types';

interface RecentActivityProps {
  data: ActivityItemType[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isLoading: boolean;
  hasError: boolean;
}

export const RecentActivity = ({
  data,
  fetchNextPage,
  hasNextPage,
  isLoading,
  hasError,
}: RecentActivityProps) => {
  const { formatMessage } = useIntl();

  const texts = {
    errorTitle: formatMessage({ id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_ERROR_TITLE' }),
    errorDescription: formatMessage({ id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_ERROR_DESCRIPTION' }),
    emptyTitle: formatMessage({ id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_EMPTY_TITLE' }),
    emptyDescription: formatMessage({ id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_EMPTY_DESCRIPTION' }),
    requestor: formatMessage({ id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_REQUESTOR' }),
    copy: formatMessage({ id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_COPY' }),
    error: formatMessage({ id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_LOAD_MORE_ERROR' }),
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const [displayError, setDisplayError] = useState(false);

  const [previousScrollPosition, setPreviousScrollPosition] = useState(0);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);

  const onScroll = () => {
    const handleScrollEnd = (container: HTMLDivElement) => {
      const hasReachedBottom =
        container.scrollTop + container.clientHeight >= container.scrollHeight;
      if (hasReachedBottom && hasNextPage) {
        setCurrentScrollPosition(container.scrollTop);
        fetchNextPage();
      }
    };
    containerRef.current && handleScrollEnd(containerRef.current);
  };

  useEffect(() => {
    const shouldFetchNextPage = () => {
      const container = containerRef.current;
      if (!container) return false;

      const hasSpaceAvailable = container.clientHeight >= container.scrollHeight;
      return hasSpaceAvailable && hasNextPage && !isLoading;
    };

    if (shouldFetchNextPage()) {
      fetchNextPage();
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (isLoading) {
      setDisplayError(false);
      return;
    }

    if (hasError) {
      containerRef.current?.scrollTo?.(0, previousScrollPosition);
      setDisplayError(true);
      return;
    }

    setPreviousScrollPosition(currentScrollPosition);
    setDisplayError(false);
  }, [isLoading]);

  if (data.length === 0 && hasError) {
    return (
      <EmptyStateContainer
        data-testid={'recent-activity-error'}
        title={texts.errorTitle}
        description={texts.errorDescription}
        customIlustration={{
          src: ErrorBox,
          alt: texts.error,
        }}
      />
    );
  }

  if (data.length === 0 && !hasError) {
    return (
      <EmptyStateContainer
        data-testid={'recent-activity-empty'}
        title={texts.emptyTitle}
        description={texts.emptyDescription}
      />
    );
  }

  return (
    <>
      {displayError && (
        <LoadMoreError
          data-testid={'recent-activity-next-item-error'}
          showCloseButton
          message={texts.error}
          type="error"
          onClose={() => setDisplayError(false)}
          css={{ width: '100%' }}
        />
      )}
      <ItemListContainer
        data-testid={'recent-activity-list'}
        ref={containerRef}
        onScroll={onScroll}
      >
        {data.map(({ title, description, requestorInfo, userId }, index) => (
          <ItemContainer data-testid={`recent-activity-item-${index}`} key={index}>
            <ItemIcon data-testid={`recent-activity-item-icon-${index}`} />
            <ItemInfo>
              <Paragraph data-testid={`recent-activity-item-title-${index}`} weight={'semibold'}>
                {title}
              </Paragraph>
              <Paragraph data-testid={`recent-activity-item-description-${index}`}>
                {description}
              </Paragraph>
              <ItemRequestor data-testid={`recent-activity-item-requestor-${index}`}>
                <Paragraph colortype="disabled" weight={'semibold'}>
                  {texts.requestor}:{' '}
                </Paragraph>
                <CopyButton
                  text={requestorInfo}
                  color="secondary"
                  size={'medium'}
                  successMessage={texts.copy}
                  clipBoardText={userId}
                />
              </ItemRequestor>
            </ItemInfo>
          </ItemContainer>
        ))}
        {hasNextPage && (
          <ItemContainer data-testid={'recent-activity-loading-next-item'}>
            <ItemIcon />
            <ItemInfo>
              <SkeletonLoader height="1.5rem" />
              <SkeletonLoader height="1.5rem" />
              <SkeletonLoader height="1.5rem" />
            </ItemInfo>
          </ItemContainer>
        )}
      </ItemListContainer>
    </>
  );
};
