import { Button, Dialog, Heading, Paragraph } from '@hexa-ui/components';
import { History2 } from '@hexa-ui/icons';
import { useUserMetadata } from 'admin-portal-shared-services';
import { SEGMENT_ANALYTICS_APP_NAME, SEGMENT_ANALYTICS_PERMISSION_GROUP } from 'config/constants';
import { useRecentActivity } from 'hooks/useRecentActivity';
import useTrackingSegment from 'hooks/useTrackingSegment';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { ActionsContainer, HeaderContainer } from './ShowRecentActivity.styles';
import { RecentActivity } from './components/RecentActivity';
import { formatActivityItems } from './format/format';

const ShowRecentActivityIcon = () => <History2 size="medium" />;

interface ShowRecentActivityProps {
  storeName: string;
  storeId: string;
  storeStatus: string;
  storeDefaultSeller: string;
}

export const ShowRecentActivity = ({
  storeName,
  storeDefaultSeller,
  storeId,
  storeStatus,
}: ShowRecentActivityProps) => {
  const intl = useIntl();

  const { formatMessage } = intl;
  const { country, id } = useParams<{ country: string; id: string }>();
  const { data: dataUserMetaData } = useUserMetadata();

  const analytics = useTrackingSegment();

  const texts = {
    showRecentActivityButton: formatMessage({ id: 'STORE_DETAILS_PAGE.SHOW_RECENT_ACTIVITY' }),
    header: formatMessage({ id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_HEADER' }),
    description: formatMessage({ id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_DESCRIPTION' }),
    close: formatMessage({ id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_CLOSE' }),
  };

  const { data, isLoading, hasError, hasNextPage, fetchNextPage } = useRecentActivity();

  const activityItems = formatActivityItems(data, storeName, intl);

  const eventElementClicked = () => {
    analytics.track('Element Clicked', {
      appDisplayName: SEGMENT_ANALYTICS_APP_NAME,
      isCoreEvent: false,
      pageLabel: 'Store Details',
      pageName: 'Store Details',
      pageUrl: `/company-management/stores/${country}/${id}`,
      userPermissionGroup: dataUserMetaData?.authorization?.groups?.filter((value) =>
        value.startsWith(SEGMENT_ANALYTICS_PERMISSION_GROUP)
      ),
      elementLocationType: 'HEADER',
      elementLocationName: 'HEADER',
      elementLabel: 'Show Recent Activity',
      elementName: 'Show Recent Activity',
      elementType: 'BUTTON',
      storeStatus,
      storeId,
      storeName,
      storeDefaultSeller,
    });
  };

  return (
    <Dialog.Root
      trigger={
        <Button
          data-testid="show-recent-activity-button"
          icon={ShowRecentActivityIcon}
          leading
          variant="secondary"
          onClick={eventElementClicked}
        >
          {texts.showRecentActivityButton}
        </Button>
      }
      title={
        <HeaderContainer>
          <Heading data-testid="recent-activity-header-title" size="H2">
            {texts.header}
          </Heading>
          <Paragraph data-testid="recent-activity-header-description">
            {texts.description}
          </Paragraph>
        </HeaderContainer>
      }
      actions={
        <ActionsContainer>
          <Dialog.Close>
            <Button data-testid="recent-activity-close-button">{texts.close}</Button>
          </Dialog.Close>
        </ActionsContainer>
      }
    >
      <RecentActivity
        data={activityItems}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isLoading={isLoading}
        hasError={hasError}
      />
    </Dialog.Root>
  );
};
