import { Alert, EmptyState } from '@hexa-ui/components';
import { styled } from 'theme';

export const ItemListContainer = styled('div', {
  marginTop: '56px',
  height: '392px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  overflowY: 'auto',
  alignItems: 'center',
});

export const ItemContainer = styled('div', {
  display: 'flex',
  gap: '10px',

  width: '100%',
});

export const ItemIcon = styled('div', {
  marginLeft: '1px',
  width: '4px',
  height: '100%',
  borderRadius: '5px',
  backgroundColor: 'rgba(20, 20, 20, 0.56)',
  minHeight: '80px',
});

export const ItemInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  width: '100%',
  minHeight: '80px',
});

export const ItemRequestor = styled('div', {
  display: 'flex',
});

export const LoadMoreError = styled(Alert, {
  position: 'relative',
  top: '40px',
});

export const EmptyStateContainer = styled(EmptyState.PageLevel, {
  marginTop: '56px',
  alignItems: 'center',
  width: 'auto !important',
});
