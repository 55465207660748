import { styled } from 'theme';

export const ImageContainer = styled('div', {
  '& .fui-dropzone-root': {
    minHeight: '250px',
    '& > div > div': {
      height: 'auto',
      minHeight: '248px',
      '& p': {
        padding: 0,
      },
      '& [class*="alert"]': {
        width: '100%',
        '& *': {
          lineHeight: '1.25',
        },
      },
      '& > button': {
        marginTop: '$1',
      },
      '& > div:last-of-type': {
        '& > svg': {
          width: '60px',
          height: '60px',
        },
        '& > div': {
          width: '36px',
          height: '36px',
          '& > svg': {
            width: '22px',
            height: '22px',
          },
        },
      },
    },
    '& img': {
      borderRadius: '$4',
      height: '250px',
    },
    '& button': {
      minWidth: 'auto',
    },
  },
  flex: '1',
  '@desktopSmall': {
    flex: '0 0 250px',
  },
});
