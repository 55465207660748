import { formatRequestorInfo } from '../../commonFormatters';
import { ActivityItemType, IntlConfigType } from '../../format.types';

type DissociateStoreParams = {
  intlConfig: IntlConfigType;
  storeName: string;
  vendorName: string;
  userName: string;
  creationDate: Date;
  userId: string;
};

const format = ({
  intlConfig,
  storeName,
  vendorName,
  userName,
  creationDate,
  userId,
}: DissociateStoreParams): ActivityItemType => {
  const { formatMessage } = intlConfig.intl;

  return {
    title: formatMessage(
      { id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_DISSOCIATE_TITLE' },
      { storeName }
    ),
    description: formatMessage(
      { id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_DISSOCIATE_DESCRIPTION' },
      { storeName, vendorName }
    ),
    requestorInfo: formatRequestorInfo(userName, creationDate, intlConfig),
    userId,
  };
};

export const getDissociateStoreItems = ({
  intlConfig,
  storeName,
  vendorName,
  userName,
  creationDate,
  userId,
}: DissociateStoreParams) => {
  return [
    format({
      intlConfig,
      storeName,
      vendorName,
      userName,
      creationDate,
      userId,
    }),
  ];
};
