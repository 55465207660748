import { styled } from 'theme';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
});

export const StoreInfo = styled('div', {
  display: 'flex',
  gap: '$10',

  div: {
    display: 'flex',
    alignItems: 'center',
    gap: '$4',
  },
});
