import { styled } from 'theme';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$2',

  variants: {
    disabled: {
      true: {
        '& > p': {
          color: '$interfaceLabelDisabled',
        },
      },
    },
  },
});
