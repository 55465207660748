import { AuditLog, AuditLogGetParams, useGetAuditLogs } from 'admin-portal-shared-services';
import {
  APP_ID,
  DEFAULT_PAGE_SIZE,
  SORT_ORDER_DESCENDENT,
  STORE_UPDATES_ENTITY,
} from 'config/constants';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const get30DaysAgoISODate = () => {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  return date.toISOString();
};

export const useRecentActivity = () => {
  const [data, setData] = useState<AuditLog[]>([]);
  const [page, setPage] = useState(0);

  const [hasNextPage, setHasNextPage] = useState(false);
  const [finalDate, setFinalDate] = useState<string>(new Date().toISOString());
  const [initialDate] = useState(get30DaysAgoISODate());

  const { id: storeId, country } = useParams();

  const {
    data: pageData,
    isLoading,
    hasError,
  } = useGetAuditLogs({
    initialDate,
    finalDate,
    country,
    page,
    entity: STORE_UPDATES_ENTITY,
    entityId: storeId,
    appId: APP_ID,
    pageSize: DEFAULT_PAGE_SIZE,
    sort: SORT_ORDER_DESCENDENT,
  } as AuditLogGetParams);

  const fetchNextPage = () => {
    if (!hasError) {
      if (hasNextPage) {
        setPage(page + 1);
      }
    } else {
      setFinalDate(new Date().toISOString());
    }
  };

  useEffect(() => {
    if (pageData) {
      setData((prevData) => [...prevData, ...pageData.content]);
      setHasNextPage(!!pageData.pagination.next);
    }
  }, [pageData]);

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    hasError,
  };
};
