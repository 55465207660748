import { useHasPermission } from 'admin-portal-shared-services';
import { permissions } from 'config/permissions';

interface Permissions {
  canAccessStoreManagement: boolean;
  canAccessAllCountries: boolean;
  canManageStores: boolean;
}

export const usePermissions = (): Permissions => {
  const canAccessStoreManagement = useHasPermission(
    [permissions.StoreManagement.READ, permissions.StoreManagement.WRITE],
    'OR'
  );

  const canAccessAllCountries =
    useHasPermission(permissions.StoreManagement.ALL_COUNTRIES) && canAccessStoreManagement;

  const canManageStores = useHasPermission(permissions.StoreManagement.WRITE);

  return {
    canAccessStoreManagement,
    canAccessAllCountries,
    canManageStores,
  };
};
