import { ONE_STORE_MANAGEMENT_BFF_BASE_URL } from 'config/constants';
import { EndpointsToLog } from 'features/log/log';
import { BaseQueryFnArgs } from 'services/baseApi';
import {
  GetStoreAvailableVendorsParams,
  GetStoreDetailsParams,
  GetStoreVendorsParams,
  GetStoresParams,
  UpdateStoreParams,
} from './types';

export * from './types';

export const getStoresQuery = ({
  page,
  pageSize,
  country,
  name,
  storeId,
  vendorId,
}: GetStoresParams): BaseQueryFnArgs => ({
  url: `${ONE_STORE_MANAGEMENT_BFF_BASE_URL}/v1/stores`,
  method: 'GET',
  params: { page, pageSize, name, storeId, vendorId },
  headers: {
    country: country.toUpperCase(),
  },
});

export const getStoreDetailsQuery = ({ id, country }: GetStoreDetailsParams): BaseQueryFnArgs => ({
  url: `${ONE_STORE_MANAGEMENT_BFF_BASE_URL}/v1/stores/${id}`,
  method: 'GET',
  headers: {
    country: country.toUpperCase(),
  },
});

export const getStoreVendorsQuery = ({
  id,
  page,
  pageSize,
  country,
  name,
  vendorId,
}: GetStoreVendorsParams): BaseQueryFnArgs => ({
  url: `${ONE_STORE_MANAGEMENT_BFF_BASE_URL}/v1/stores/${id}/vendors`,
  method: 'GET',
  params: { page, pageSize, name, vendorId },
  headers: {
    country: country.toUpperCase(),
  },
});

export const getStoreAvailableVendorsQuery = ({
  id,
  country,
  storeStatus,
}: GetStoreAvailableVendorsParams): BaseQueryFnArgs => ({
  url: `${ONE_STORE_MANAGEMENT_BFF_BASE_URL}/v1/stores/${id}/available-vendors`,
  method: 'GET',
  headers: {
    country: country.toUpperCase(),
    storeStatus,
  },
});

export const updateStoreMutation = ({
  country,
  logData,
  ...payload
}: UpdateStoreParams): BaseQueryFnArgs => ({
  url: `${ONE_STORE_MANAGEMENT_BFF_BASE_URL}/v1/stores/${payload.id}`,
  method: 'PATCH',
  data: payload,
  headers: {
    country: country.toUpperCase(),
    log: EndpointsToLog.updateStore,
    logExtraData: JSON.stringify(logData),
  },
});
