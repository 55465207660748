import { Select } from '@hexa-ui/components';
import { World } from '@hexa-ui/icons';
import { useCountries } from 'hooks/useCountries';
import { useMemo, useState } from 'react';
import { injectIntl, useIntl } from 'react-intl';

function WorldIcon() {
  return <World data-ref="world-icon" fr={undefined} />;
}

const CountrySelector = (): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const { getCountryName, handleCountryParam, supportedCountries, initialCountry, isLoading } =
    useCountries();

  const [country, setCountry] = useState<string | null>(initialCountry);

  const handleChange = (countryCode: string) => {
    handleCountryParam(countryCode);
    setCountry(countryCode);
  };

  const menuItems = useMemo(
    () =>
      supportedCountries
        .map((countryCode) => (
          <Select.Option
            key={countryCode}
            value={countryCode}
            data-name={getCountryName(countryCode)}
          >
            {getCountryName(countryCode)}
          </Select.Option>
        ))
        .sort((a, b) => a.props['data-name'].localeCompare(b.props['data-name'])),
    [supportedCountries]
  );
  const isSupportedCountriesHasMoreThenOneCountry = (): boolean => menuItems.length > 1;

  return isSupportedCountriesHasMoreThenOneCountry() && !isLoading ? (
    <Select.Root
      size="small"
      shape="pill"
      icon={<WorldIcon />}
      onChange={handleChange}
      aria-label={formatMessage({ id: 'COMPONENTS.FILTER_BY_COUNTRY' })}
      value={country!}
      width="max-content"
    >
      {menuItems}
    </Select.Root>
  ) : null;
};

export default injectIntl(CountrySelector);
