import { Form } from 'formik';
import { styled } from 'theme';

export const FormContainer = styled(Form, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
  paddingBottom: '$8',
  maxWidth: '1920px',
  margin: '0 auto',
});
