import { useAuthenticationService } from 'admin-portal-shared-services';
import { Countries } from 'domains/Countries';
import { usePermissions } from './usePermissions';

export function useGetSupportedCountries(): string[] {
  const authentication = useAuthenticationService();
  const { canAccessAllCountries } = usePermissions();
  const supportedCountries = Object.values(Countries);

  if (canAccessAllCountries) return supportedCountries;

  const userCountries = authentication.getSupportedCountries();
  const filteredCountries = userCountries.filter((option) =>
    supportedCountries.includes(option as Countries)
  );

  return filteredCountries;
}
