// InfiniteSelect.tsx
import { LoadingDots, Select, SkeletonLoader } from '@hexa-ui/components';
import { useIsVisible } from 'hooks/useIsVisible';
import { useEffect } from 'react';
import {
  FirstLoadingContainer,
  LoadingLabel,
  MoreItemsContainer,
  Wrapper,
} from './InfiniteSelect.styles';

interface InfiniteSelectProps {
  selectProps: Select.SelectProps;
  children: React.ReactNode;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isFirstLoading?: boolean;
  dataTestId?: string;
}

export default function InfiniteSelect({
  selectProps,
  children,
  hasNextPage,
  fetchNextPage,
  isFirstLoading,
  dataTestId,
}: Readonly<InfiniteSelectProps>): JSX.Element {
  const { isVisible, ref } = useIsVisible();

  useEffect(() => {
    if (isVisible && hasNextPage) {
      fetchNextPage();
    }
  }, [isVisible, fetchNextPage, hasNextPage]);

  if (isFirstLoading) {
    return (
      <FirstLoadingContainer data-testid={`${dataTestId}-loading`}>
        <LoadingLabel size="small" weight="semibold">
          {selectProps.label}
        </LoadingLabel>
        <SkeletonLoader height="48px" width="100%" />
      </FirstLoadingContainer>
    );
  }

  return (
    <Wrapper>
      <Select.Root {...selectProps} data-testid={dataTestId}>
        {children}
        <MoreItemsContainer data-testid="more-items-container" hasNextPage={hasNextPage} ref={ref}>
          {hasNextPage && <LoadingDots size="large" />}
        </MoreItemsContainer>
      </Select.Root>
    </Wrapper>
  );
}
