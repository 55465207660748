import { Card } from '@hexa-ui/components';
import { styled } from 'theme';

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
  paddingBottom: '$6',
});

export const ThumbnailsContainer = styled(Card, {
  '&&': {
    display: 'flex',
    flexDirection: 'row',
  },
  alignItems: 'center',
  gap: '120px',
  padding: '$6',
  marginTop: '$2',
  marginBottom: '$8',
});

export const Thumbnail = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
  paddingBottom: '$6',
});

export const ActionsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '$2',
});
