import { formatRequestorInfo, formatString } from '../../../commonFormatters';
import { ActivityItemType, IntlConfigType } from '../../../format.types';

type EditStatusParams = {
  intlConfig: IntlConfigType;
  storeName: string;
  status: string;
  userName: string;
  creationDate: Date;
  userId: string;
};

export const editStatus = ({
  intlConfig,
  storeName,
  status,
  userName,
  creationDate,
  userId,
}: EditStatusParams): ActivityItemType => {
  const { formatMessage } = intlConfig.intl;

  const statusMap: Record<string, string> = {
    ACTIVE: formatMessage({ id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_STATUS_ACTIVE' }),
    INACTIVE: formatMessage({ id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_STATUS_INACTIVE' }),
    EXPERIMENTATION: formatMessage({
      id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_STATUS_EXPERIMENTATION',
    }),
  };

  return {
    title: formatMessage(
      { id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_EDIT_STATUS_TITLE' },
      { storeName, status: formatString(statusMap[status]) }
    ),
    description: formatMessage(
      { id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_EDIT_STATUS_DESCRIPTION' },
      { storeName, status: formatString(statusMap[status]) }
    ),
    requestorInfo: formatRequestorInfo(userName, creationDate, intlConfig),
    userId,
  };
};
