import { formatString } from '../../commonFormatters';
import { ActivityItemType, IntlConfigType, MetadataType } from '../../format.types';
import { getEditConfigurationsItems } from './attributes/configurations';
import { editDefaultStoreSeller } from './attributes/defaultSeller';
import { editStoreImage } from './attributes/image';
import { editName } from './attributes/name';
import { editStatus } from './attributes/status';

type EditStoreParams = {
  intlConfig: IntlConfigType;
  storeName: string;
  vendorName: string;
  fields: MetadataType['fields'];
  userName: string;
  creationDate: Date;
  userId: string;
};

export const getEditStoreItems = ({
  intlConfig,
  vendorName,
  fields,
  storeName,
  userName,
  creationDate,
  userId,
}: EditStoreParams) => {
  return Object.keys(fields).reduce<ActivityItemType[]>((editSubList, key) => {
    switch (key) {
      case 'name':
        formatString(fields.name);
        return [
          ...editSubList,
          editName({
            intlConfig,
            storeName,
            newStoreName: formatString(fields.name),
            userName,
            creationDate,
            userId,
          }),
        ];
      case 'status':
        return [
          ...editSubList,
          editStatus({
            intlConfig,
            storeName,
            status: fields.status,
            userName,
            creationDate,
            userId,
          }),
        ];
      case 'defaultVendor':
        return [
          ...editSubList,
          editDefaultStoreSeller({
            intlConfig,
            storeName,
            defaultSellerName: formatString(fields.defaultVendor.name),
            userName,
            creationDate,
            userId,
          }),
        ];
      case 'image':
        return [
          ...editSubList,
          editStoreImage({
            intlConfig,
            storeName,
            imageLink: formatString(fields.image),
            userName,
            creationDate,
            userId,
          }),
        ];
      case 'configurations':
        return [
          ...editSubList,
          ...getEditConfigurationsItems({
            intlConfig,
            vendorName,
            storeName,
            fieldConfigurations: fields.configurations,
            userName,
            creationDate,
            userId,
          }),
        ];
      default:
        return editSubList;
    }
  }, []);
};
