import { formatString } from '../../../../commonFormatters';
import { ActivityItemType, IntlConfigType, MetadataType } from '../../../../format.types';
import { editConfigurationsImage } from './attributes/image';
import { editConfigurationsStatus } from './attributes/status';

type EditConfigurationsStoreParams = {
  intlConfig: IntlConfigType;
  storeName: string;
  vendorName: string;
  fieldConfigurations: MetadataType['fields']['configurations'];
  userName: string;
  creationDate: Date;
  userId: string;
};

export const getEditConfigurationsItems = ({
  intlConfig,
  storeName,
  fieldConfigurations,
  userName,
  creationDate,
  userId,
}: EditConfigurationsStoreParams) => {
  return Object.keys(fieldConfigurations).reduce<ActivityItemType[]>(
    (editConfigSubList, configKey) => {
      const config = fieldConfigurations[configKey as keyof typeof fieldConfigurations];
      if (config.image !== undefined) {
        editConfigSubList.push(
          editConfigurationsImage({
            intlConfig,
            storeName,
            imageLink: formatString(config.image),
            configType: configKey,
            userName,
            creationDate,
            userId,
          })
        );
      }
      if (config.enabled !== undefined) {
        editConfigSubList.push(
          editConfigurationsStatus({
            intlConfig,
            storeName,
            status: config.enabled,
            configType: configKey,
            userName,
            creationDate,
            userId,
          })
        );
      }
      return editConfigSubList;
    },
    []
  );
};
