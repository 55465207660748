import { Card, Paragraph } from '@hexa-ui/components';
import { styled } from 'theme';

export const SectionCard = styled(Card, {
  padding: '$6 $6 $10 $6',
});

export const FlexContainer = styled('div', {
  display: 'flex',
  columnGap: '$24',
  rowGap: '$12',
  marginTop: '$8',
  marginBottom: '$4',
  flexWrap: 'wrap',
  '@desktopSmall': {
    flexWrap: 'nowrap',
  },
});

export const ItemContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
  flex: '1',
  minWidth: '250px',
  marginBottom: 'auto',
  '& > :last-child': {
    marginTop: '$2',
  },
  '@desktopSmall': {
    flex: '0 0 250px',
  },
});

export const Description = styled(Paragraph, {
  marginTop: '$2',
});
