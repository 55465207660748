export enum Countries {
  AR = 'AR',
  BR = 'BR',
  CA = 'CA',
  CO = 'CO',
  DO = 'DO',
  EC = 'EC',
  ES = 'ES',
  HN = 'HN',
  KR = 'KR',
  MX = 'MX',
  PA = 'PA',
  PE = 'PE',
  PY = 'PY',
  SV = 'SV',
  ZA = 'ZA',
}
