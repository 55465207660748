/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/rules-of-hooks */
import { useAuthenticationService, useEnvService } from 'admin-portal-shared-services';
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { API_BASE_PATH, EU_COUNTRIES } from 'config/constants';
import { Countries } from 'domains/Countries';
import { EndpointsToLog } from 'features/log/log';
import { logDataByEndpoint } from 'utils/log';

export interface IRequest {
  url: string;
  body?: AxiosRequestConfig['data'];
  config?: AxiosRequestConfig;
}

export type With<BaseType, ObjectKey extends string, ComposedType> = BaseType & {
  [k in ObjectKey]: ComposedType;
};

export type IGenericDataWithError<T, E = unknown> = With<
  AxiosResponse<T>,
  'hasError',
  boolean | undefined
> &
  (AxiosError<E> | undefined);

export const initialAxios = Axios.create();

const authentication = useAuthenticationService();

const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

authentication.enhancedAxios(
  initialAxios as unknown as Parameters<typeof authentication.enhancedAxios>[0],
  {
    headers: [
      {
        country: userCountry!,
        noorders: localStorage.getItem('noOrders') ?? '',
      },
    ],
  }
);

initialAxios.interceptors.request.use((config) => {
  const isSit = useEnvService().getEnv() === 'QA';
  if (EU_COUNTRIES.includes(config.headers.country) && config.url && !isSit) {
    const path = config.url.split('/api/')[1];

    config.url = `/api/eu/${path}`;
  }

  if (config.headers.has('log') && (!config.data || !config.headers.has('logExtraData'))) {
    const message = `Missing 'data' (body) or 'logExtraData' header to successfully log ${config.headers.get(
      'log'
    )} endpoint`;
    console.error(message);
    throw new Error(message);
  }

  return config;
});

initialAxios.interceptors.response.use((response) => {
  if (response.config.headers.has('log')) {
    logDataByEndpoint({
      endpoint: response.config.headers.get('log') as EndpointsToLog,
      data: JSON.parse(response.config.data),
      extraData: JSON.parse(response.config.headers.get('logExtraData') as string),
      country: response.config.headers.get('country') as Countries,
    });
  }
  return response;
});

const Api = {
  get: <T, E = unknown>({ url, config }: IRequest): Promise<IGenericDataWithError<T, E>> =>
    initialAxios
      .get(`${API_BASE_PATH}${url}`, config)
      .catch((error) => ({ hasError: true, ...error })),
  post: <T, E = unknown>({ url, body, config }: IRequest): Promise<IGenericDataWithError<T, E>> =>
    initialAxios.post(`${API_BASE_PATH}${url}`, body, config).catch((error) => ({
      hasError: true,
      ...error,
    })),
  put: <T, E = unknown>({ url, body, config }: IRequest): Promise<IGenericDataWithError<T, E>> =>
    initialAxios.put(`${API_BASE_PATH}${url}`, body, config).catch((error) => ({
      hasError: true,
      ...error,
    })),
  patch: <T, E = unknown>({ url, body, config }: IRequest): Promise<IGenericDataWithError<T, E>> =>
    initialAxios.patch(`${API_BASE_PATH}${url}`, body, config).catch((error) => ({
      hasError: true,
      ...error,
    })),
  delete: <T, E = unknown>({ url, config }: IRequest): Promise<IGenericDataWithError<T, E>> =>
    initialAxios
      .delete(`${API_BASE_PATH}${url}`, config)
      .catch((error) => ({ hasError: true, ...error })),
};

export default Api;
