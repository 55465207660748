import {
  HandleFileUpload,
  ImageUploader as OriginalImageUploader,
  Zones,
} from '@content-shared-components/image-uploader';
import { ExtFile } from '@files-ui/react';
import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import { forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import { ImageContainer } from './ImageUploader.styles';

export interface ImageUploaderProps {
  imageUrl?: string;
  onChange?: (files: ExtFile[]) => void;
  dataTestId?: string;
  maxWidth: number;
  maxHeight: number;
  maxFileSize: number;
}

type PrefferedLanguage = React.ComponentProps<typeof OriginalImageUploader>['preferredLanguage'];

const ImageUploader = forwardRef<HandleFileUpload, ImageUploaderProps>(function ImageUploader(
  { imageUrl, onChange, maxFileSize, maxHeight, maxWidth, dataTestId },
  ref
) {
  const { country } = useParams<{ country: string }>();
  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2();

  const language = (preferredLanguage ?? defaultLanguage).split('-')[0] as PrefferedLanguage;

  return (
    <ImageContainer data-testid={dataTestId}>
      <OriginalImageUploader
        key={`image-uploader-${imageUrl}`}
        zone={country?.toLowerCase() as Zones}
        ref={ref}
        preferredLanguage={language}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        fileSizeLimit={maxFileSize}
        fileExtensions={['jpg', 'jpeg', 'png']}
        imageUrl={imageUrl}
        onChange={onChange}
        imageFit="contain"
      />
    </ImageContainer>
  );
});

export default ImageUploader;
