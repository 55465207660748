import { Button, Dialog, Heading, LoadingDots, Paragraph } from '@hexa-ui/components';
import { TypeToast, useToast, useUserMetadata } from 'admin-portal-shared-services';
import { SEGMENT_ANALYTICS_APP_NAME, SEGMENT_ANALYTICS_PERMISSION_GROUP } from 'config/constants';
import useTrackingSegment from 'hooks/useTrackingSegment';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { emptySplitApi, useUpdateVendorStoresMutation } from 'services/baseApi';
import { ActionsContainer } from './DissociateFromStoreModal.styles';
import { DissociateFromStoreModalProps } from './DissociateFromStoreModal.types';

export const DissociateFromStoreModal = ({
  isOpen,
  onClose,
  data: { store, vendor, country },
}: DissociateFromStoreModalProps) => {
  const { formatMessage } = useIntl();
  const { data: dataUserMetaData } = useUserMetadata();
  const analytics = useTrackingSegment();

  const [updateVendorStores, { isLoading, isSuccess, isError }] = useUpdateVendorStoresMutation();

  const elementClickedEvent = (label: string) => {
    analytics.track('Element Clicked', {
      appDisplayName: SEGMENT_ANALYTICS_APP_NAME,
      isCoreEvent: true,
      pageLabel: 'Store Details',
      pageName: 'Store Details',
      pageUrl: `/company-management/stores/${country}/${store.id}`,
      userPermissionGroup: dataUserMetaData?.authorization?.groups?.filter((value) =>
        value.startsWith(SEGMENT_ANALYTICS_PERMISSION_GROUP)
      ),
      vendorCountry: country,
      vendorDisplayName: store?.name,
      vendorId: store?.id,
      storeId: store?.id,
      storeCountry: country,
      storeName: store?.name,
      elementLocationType: 'MODAL',
      elementLocationName: 'MODAL',
      elementLabel: label,
      elementName: label,
      elementType: 'BUTTON',
    });
  };

  const toast = useToast();
  const navigate = useNavigate();

  const texts = {
    header: formatMessage(
      { id: 'STORE_DETAILS_PAGE.DISSOCIATE_MODAL_HEADER' },
      { store: store.name }
    ),
    content: formatMessage(
      { id: 'STORE_DETAILS_PAGE.DISSOCIATE_MODAL_CONTENT' },
      { company: vendor?.name, store: store.name }
    ),
    confirm: formatMessage({ id: 'STORE_DETAILS_PAGE.DISSOCIATE_MODAL_CONFIRM' }),
    cancel: formatMessage({ id: 'STORE_DETAILS_PAGE.DISSOCIATE_MODAL_CANCEL' }),
    success: formatMessage(
      { id: 'STORE_DETAILS_PAGE.DISSOCIATE_MODAL_SUCCESS' },
      { company: vendor?.name }
    ),
    error: formatMessage(
      { id: 'STORE_DETAILS_PAGE.DISSOCIATE_MODAL_ERROR' },
      { company: vendor?.name }
    ),
    successButton: formatMessage({ id: 'STORE_DETAILS_PAGE.DISSOCIATE_MODAL_SUCCESS_BUTTON' }),
  };

  const onConfirm = () => {
    updateVendorStores({
      vendorId: vendor.id,
      country,
      stores: [
        {
          id: store.id,
          associated: false,
        },
      ],
      logData: {
        vendorId: vendor.id,
        vendorName: vendor.name,
      },
    });
    elementClickedEvent('Yes, dissociate');
  };

  const handleError = () => {
    toast.notify({
      message: texts.error,
      type: TypeToast.ERROR,
    });
  };

  const handleSuccess = () => {
    toast.notify({
      message: texts.success,
      type: TypeToast.SUCCESS,
      actions: [
        {
          name: texts.successButton,
          action: () => navigate(`/company-management/stores/${country}`),
        },
      ],
    });
  };

  useEffect(() => {
    emptySplitApi.util.resetApiState();
  }, []);

  useEffect(() => {
    if (isError) {
      handleError();
      onClose();
    } else if (isSuccess) {
      handleSuccess();
      onClose();
    }
  }, [isError, isSuccess]);

  if (!isOpen) return null;

  return (
    <div data-testid="dissociate-from-store-modal">
      <Dialog.Root
        open={isOpen}
        contentCss={{ width: '640px' }}
        title={
          <Heading data-testid="dissociate-store-header" css={{ width: '480px' }} size="H2">
            {texts.header}
          </Heading>
        }
        headerHeight="auto"
        onClose={onClose}
        actions={
          <ActionsContainer>
            <Dialog.Close>
              <Button
                data-testid="dissociate-store-confirm-button"
                onClick={onConfirm}
                size="medium"
                disabled={isLoading}
                variant="destructive"
              >
                {isLoading ? (
                  <LoadingDots data-testid="dissociate-confirm-loading-icon" />
                ) : (
                  texts.confirm
                )}
              </Button>
            </Dialog.Close>
            <Dialog.Close>
              <Button
                data-testid="dissociate-store-cancel-button"
                onClick={() => {
                  elementClickedEvent('No, cancel');
                  onClose();
                }}
                size="medium"
                variant="secondary"
              >
                {texts.cancel}
              </Button>
            </Dialog.Close>
          </ActionsContainer>
        }
      >
        <Paragraph data-testid="dissociate-store-content">{texts.content}</Paragraph>
      </Dialog.Root>
    </div>
  );
};
