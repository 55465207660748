import { HandleFileUpload } from '@content-shared-components/image-uploader';
import { ExtFile } from '@files-ui/react';
import ImageUploader from 'components/ImageUploader/ImageUploader';
import { Field, FieldProps } from 'formik';
import set from 'lodash/set';
import { RefObject, useState } from 'react';

const MAX_WIDTH = 300;
const MAX_HEIGHT = 300;
const MAX_FILE_SIZE = 1 * 1024 * 1024;

export interface FormikImageProps {
  name: string;
  maxWidth?: number;
  maxHeight?: number;
  maxFileSize?: number;
  formikRef: RefObject<HandleFileUpload>;
}

interface ValidateImageProps {
  file?: File;
  maxWidth: number;
  maxHeight: number;
  maxFileSize: number;
}

const validateImage = ({
  file,
  maxFileSize,
  maxHeight,
  maxWidth,
}: ValidateImageProps): Promise<boolean> => {
  if (!file) {
    return Promise.resolve(true);
  }

  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const { width, height } = img;

      if (width > maxWidth || height > maxHeight || file.size > maxFileSize) {
        resolve(false);
      } else {
        resolve(true);
      }
    };
    img.onerror = () => {
      resolve(false);
    };
    img.src = URL.createObjectURL(file);
  });
};

function FormikImage({
  name,
  maxFileSize = MAX_FILE_SIZE,
  maxHeight = MAX_HEIGHT,
  maxWidth = MAX_WIDTH,
  formikRef,
}: Readonly<FormikImageProps>): JSX.Element {
  const [state, setState] = useState('');

  return (
    <Field name={name}>
      {({ form, meta }: FieldProps) => {
        const onChangeImage = async (files: ExtFile[]) => {
          const isImageValid = await validateImage({
            file: files?.[0]?.file,
            maxWidth,
            maxHeight,
            maxFileSize,
          });
          if (!isImageValid) {
            form.setFieldValue(name, 'invalid');
            setState('-invalid');
            return;
          }
          if (meta.initialValue === undefined && !files.length) {
            form.setValues((previous: unknown[]) => set(previous, name, undefined));
            return;
          }
          form.setFieldValue(name, files.length ? 'changed' : null);
          form.setFieldTouched(name, true);
          setState(files.length ? '-changed' : '-removed');
        };

        return (
          <ImageUploader
            dataTestId={`formik-image${state}`}
            ref={formikRef}
            onChange={onChangeImage}
            imageUrl={meta.initialValue}
            maxFileSize={maxFileSize}
            maxHeight={maxHeight}
            maxWidth={maxWidth}
          />
        );
      }}
    </Field>
  );
}

export default FormikImage;
