import { formatRequestorInfo } from '../../../../../commonFormatters';
import { ActivityItemType, IntlConfigType } from '../../../../../format.types';

type EditConfigurationsStatusParams = {
  intlConfig: IntlConfigType;
  storeName: string;
  configType: string;
  status: boolean;
  userName: string;
  creationDate: Date;
  userId: string;
};

export const editConfigurationsStatus = ({
  intlConfig,
  storeName,
  configType,
  status,
  userName,
  creationDate,
  userId,
}: EditConfigurationsStatusParams): ActivityItemType => {
  const { formatMessage } = intlConfig.intl;

  const configMap: Record<string, string> = {
    promotions: formatMessage({
      id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_CONFIGURATIONS_PROMOTIONS',
    }),
    miscellaneous: formatMessage({
      id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_CONFIGURATIONS_MISCELLANEOUS',
    }),
  };

  const statusMap: Record<string, string> = {
    true: formatMessage({
      id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_CONFIGURATIONS_STATUS_ON',
    }),
    false: formatMessage({
      id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_CONFIGURATIONS_STATUS_OFF',
    }),
  };

  return {
    title: formatMessage(
      { id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_STATUS_TITLE' },
      { storeName, config: configMap[configType], status: statusMap[status as unknown as string] }
    ),
    description: formatMessage(
      { id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_STATUS_DESCRIPTION' },
      { storeName, config: configMap[configType], status: statusMap[status as unknown as string] }
    ),
    requestorInfo: formatRequestorInfo(userName, creationDate, intlConfig),
    userId,
  };
};
