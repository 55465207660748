import { styled } from 'theme';

export const LabelContainer = styled('div', {
  display: 'flex',
  gap: '$2',
  alignItems: 'center',
  '& [data-testid="tooltip"]': {
    height: '15px',
  },
  variants: {
    isDisabled: {
      true: {
        '& label, & [data-testid="tooltip"] svg': {
          opacity: 0.6,
        },
      },
    },
  },
});

export const Label = styled('label', {
  fontSize: '$2',
  color: '$textSecondary',
  fontWeight: '$semibold',
});
