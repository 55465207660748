import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Container,
  DropdownContainer,
  DropdownIcon,
  DropdownItem,
  DropdownText,
  OptionsButton,
} from './DropdownMenu.style';

const ITEM_HEIGHT = 56;
const CONTAINER_HEIGHT = 32;

export interface DropdownMenuProps {
  options: {
    icon: () => JSX.Element;
    label: string;
    disabled?: boolean;
    onClick?: () => void;
  }[];
}

export function DropdownMenu({ options }: DropdownMenuProps): JSX.Element | null {
  const [showOptions, setShowOptions] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const isNearToBottom = useMemo(() => {
    const containerRect = containerRef.current?.getBoundingClientRect();
    if (containerRect) {
      return (
        window.innerHeight - containerRect.bottom < ITEM_HEIGHT * options.length + CONTAINER_HEIGHT
      );
    }
    return true;
  }, [options, window.scrollY]);

  const handleClickOutside = (event: MouseEvent) => {
    if (!containerRef?.current?.contains(event?.target as unknown as Node)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleClickOnItem = (onClick?: () => void) => () => {
    if (!onClick) {
      return;
    }
    setShowOptions(false);
    onClick();
  };

  return (
    <Container ref={containerRef}>
      <OptionsButton
        icon={DropdownIcon}
        type="button"
        data-testid="dropdown-button"
        variant="tertiary"
        size="small"
        onMouseDown={handleShowOptions}
      />
      {showOptions && (
        <DropdownContainer
          isNearToBottom={isNearToBottom}
          data-testid="dropdown-options-list"
          border="small"
          elevated="medium"
        >
          {options.map(({ icon, label, onClick }) => (
            <DropdownItem
              data-testid="dropdown-option"
              key={label}
              onClick={handleClickOnItem(onClick)}
            >
              {icon()}
              <DropdownText size="small">{label}</DropdownText>
            </DropdownItem>
          ))}
        </DropdownContainer>
      )}
    </Container>
  );
}
