import { Button, Dialog, Heading } from '@hexa-ui/components';
import { ZoomIn } from '@hexa-ui/icons';
import ImageComponent from 'components/ImageComponent/ImageComponent';
import { Thumbnail } from 'pages/StoreSettingsPage/StoreSettingsPageView';
import { useIntl } from 'react-intl';
import { ButtonGrid, IconButton } from './ThumbnailModal.styles';

interface ThumbnailModalProps {
  thumbnail: Thumbnail;
}

/**
 * @deprecated
 */
const ThumbnailModal = ({ thumbnail }: ThumbnailModalProps) => {
  const { formatMessage } = useIntl();

  const ModalActions = (
    <ButtonGrid>
      <Dialog.Close>
        <Button data-testid="modal-close-button" variant="secondary">
          {formatMessage({ id: 'STORE_SETTINGS_PAGE.THUMBNAIL_MODAL_CLOSE' })}
        </Button>
      </Dialog.Close>
    </ButtonGrid>
  );

  return (
    <Dialog.Root
      title={<Heading size="H2">{thumbnail.label}</Heading>}
      actions={ModalActions}
      trigger={<IconButton size="small" icon={ZoomIn} variant="secondary" />}
    >
      <ImageComponent src={thumbnail.image} size="461px" />
    </Dialog.Root>
  );
};

export default ThumbnailModal;
