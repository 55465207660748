import { Heading, Paragraph } from '@hexa-ui/components';
import CopyButton from 'components/CopyButton/CopyButton';
import { useIntl } from 'react-intl';
import { GetStoreDetailsResponse } from 'services/stores';
import { Container, StoreInfo } from './StoreHeader.styles';

interface StoreHeaderProps {
  store?: GetStoreDetailsResponse;
}

export const StoreHeader = ({ store }: StoreHeaderProps) => {
  const { formatMessage } = useIntl();

  const SUCCESS_MESSAGE = formatMessage({ id: 'STORE_DETAILS_PAGE.STORE_COPIED_NOTIFY' });

  return (
    <Container>
      <Heading size="H3">{store?.name}</Heading>
      <StoreInfo>
        <div>
          <Paragraph size="small" weight="semibold">
            {formatMessage({ id: 'STORE_DETAILS_PAGE.STORE_ID_LABEL' })}:
          </Paragraph>
          <CopyButton color="secondary" text={store?.id ?? ''} successMessage={SUCCESS_MESSAGE} />
        </div>
        {store?.defaultVendor && (
          <div>
            <Paragraph size="small" weight="semibold">
              {formatMessage({ id: 'STORE_DETAILS_PAGE.DEFAULT_COMPANY_LABEL' })}:
            </Paragraph>
            <Paragraph size="small">{store.defaultVendor.name}</Paragraph>
          </div>
        )}
      </StoreInfo>
    </Container>
  );
};
