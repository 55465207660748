import { formatRequestorInfo, formatString } from '../../../../../commonFormatters';
import { ActivityItemType, IntlConfigType } from '../../../../../format.types';

type EditConfigurationsImageParams = {
  intlConfig: IntlConfigType;
  storeName: string;
  configType: string;
  imageLink: string;
  userName: string;
  creationDate: Date;
  userId: string;
};

export const editConfigurationsImage = ({
  intlConfig,
  storeName,
  configType,
  imageLink,
  userName,
  creationDate,
  userId,
}: EditConfigurationsImageParams): ActivityItemType => {
  const { formatMessage } = intlConfig.intl;

  const configMap: Record<string, string> = {
    promotions: formatMessage({
      id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_CONFIGURATIONS_PROMOTIONS',
    }),
    miscellaneous: formatMessage({
      id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_CONFIGURATIONS_MISCELLANEOUS',
    }),
  };

  return {
    title: formatMessage(
      { id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_IMAGE_TITLE' },
      { storeName, config: formatString(configMap[configType]) }
    ),
    description: formatMessage(
      { id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_EDIT_CONFIGURATIONS_IMAGE_DESCRIPTION' },
      { storeName, config: formatString(configMap[configType]), imageLink }
    ),
    requestorInfo: formatRequestorInfo(userName, creationDate, intlConfig),
    userId,
  };
};
