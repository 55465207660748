import { styled } from 'theme';

export const ActionsContainer = styled('div', {
  float: 'right',
  paddingTop: '8px',
});

export const HeaderContainer = styled('div', {
  height: '104px',
  width: '703px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  background: 'white',
  position: 'relative',
});
