import { Radio } from '@hexa-ui/components';
import { styled } from 'theme';

export const RadioGroup = styled(Radio.Root, {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  columnGap: '$6',
  '& > div': {
    margin: '$2 0',
  },
  '& button': {
    minWidth: '20px',
  },
  '& label': {
    paddingLeft: '$2',
  },
  '&[data-disabled]': {
    pointerEvents: 'none',
    opacity: 0.6,
  },
});

export const Label = styled('label', {
  fontSize: '$2',
  color: '$textSecondary',
  fontWeight: '$semibold',
  variants: {
    isDisabled: {
      true: {
        opacity: 0.6,
      },
    },
  },
});
