import { QueryClientProvider as BaseQueryClient, QueryClient } from '@tanstack/react-query';

interface QueryClientProviderProsp {
  children: React.ReactNode;
}

export function QueryClientProvider({ children }: Readonly<QueryClientProviderProsp>) {
  const queryClient = new QueryClient();

  return <BaseQueryClient client={queryClient}>{children}</BaseQueryClient>;
}
