import {
  useFeatureToggleV2 as featureToggleV2,
  useFeatureToggleServiceV2,
} from 'admin-portal-shared-services';
import { OPTIMIZELY_PROJECT_NAME } from 'config/constants';
import { FeatureTogglesMap, featureToggles } from 'config/featureToggles';
import { useMemo } from 'react';

interface FeatureToggles {
  showManageStores: boolean;
}

const useGetStoreToggles = () => {
  const service = useFeatureToggleServiceV2(OPTIMIZELY_PROJECT_NAME);
  const store = service.getStore();
  return useMemo(() => store.getState(), []);
};

const useGetToggleStatus = (toggleName: FeatureTogglesMap): boolean => {
  const toggles = useGetStoreToggles();
  if (toggles[toggleName]) {
    return toggles[toggleName].value;
  }
  const [isToggleActive, isToggleLoading] = featureToggleV2(toggleName, OPTIMIZELY_PROJECT_NAME);
  return !isToggleLoading && !!isToggleActive;
};

export const useFeatureToggles = (): FeatureToggles => {
  return {
    showManageStores: useGetToggleStatus(featureToggles.SHOW_MANAGE_STORES),
  };
};
