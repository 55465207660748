import { HandleFileUpload } from '@content-shared-components/image-uploader';
import { Heading } from '@hexa-ui/components';
import FormikImage from 'components/FormikImage/FormikImage';
import FormikToggle from 'components/FormikToggle/FormikToggle';
import { RefObject } from 'react';
import { useIntl } from 'react-intl';
import { Description, FlexContainer, ItemContainer, SectionCard } from './Thumbnails.styles';

export interface ThumbnailsProps {
  promotionsRef: RefObject<HandleFileUpload>;
  miscellaneousRef: RefObject<HandleFileUpload>;
}

function Thumbnails({ miscellaneousRef, promotionsRef }: Readonly<ThumbnailsProps>): JSX.Element {
  const { formatMessage } = useIntl();

  return (
    <SectionCard elevated="small" border="medium">
      <Heading size="H3"> {formatMessage({ id: 'STORE_SETTINGS_PAGE.THUMBNAILS_TITLE' })} </Heading>
      <Description colortype="secondary" size="small">
        {formatMessage({ id: 'STORE_SETTINGS_PAGE.THUMBNAILS_SUBTITLE' })}
      </Description>
      <FlexContainer>
        <ItemContainer>
          <Heading size="H5">
            {formatMessage({ id: 'STORE_SETTINGS_PAGE.THUMBNAILS_PROMOTIONS' })}
          </Heading>
          <FormikImage formikRef={promotionsRef} name="configurations.promotions.image" />
          <FormikToggle name="configurations.promotions.enabled" showOnOffLabel />
        </ItemContainer>
        <ItemContainer>
          <Heading size="H5">
            {formatMessage({ id: 'STORE_SETTINGS_PAGE.THUMBNAILS_MISCELLANEOUS' })}
          </Heading>
          <FormikImage formikRef={miscellaneousRef} name="configurations.miscellaneous.image" />
          <FormikToggle name="configurations.miscellaneous.enabled" showOnOffLabel />
        </ItemContainer>
      </FlexContainer>
    </SectionCard>
  );
}

export default Thumbnails;
