import { AuditLog } from 'admin-portal-shared-services';
import { FormatDateOptions, IntlFormatters } from 'react-intl';
import { buildActionActivityItems } from './actions';
import { ActionType, ActivityItemType, MetadataType } from './format.types';

const dateOptions: FormatDateOptions = {
  month: 'short',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
};

export const formatActivityItems = (
  logList: AuditLog[],
  storeName: string,
  intl: IntlFormatters
): ActivityItemType[] => {
  return logList.reduce((activityItems: ActivityItemType[], auditLog) => {
    const {
      creationDate,
      action: { metadata },
    } = auditLog;
    const { action, userName, vendor: vendorStr = '{}', fields: fieldsStr = '{}' } = metadata;

    let vendor: MetadataType['vendor'];
    let fields: MetadataType['fields'];

    try {
      vendor = JSON.parse(vendorStr);
      fields = JSON.parse(fieldsStr);
    } catch (error) {
      return activityItems;
    }

    const actionActivityItems = buildActionActivityItems({
      intlConfig: {
        intl,
        dateOptions,
      },
      action: action as ActionType,
      storeName,
      vendorName: vendor.name,
      userName,
      creationDate,
      fields,
      userId: auditLog.doneBy.userId,
    });
    return [...activityItems, ...actionActivityItems];
  }, []);
};
