interface GetModalStatesProps {
  isLoading: boolean;
  isCompleted: boolean;
  errorCount: number;
  storesCount: number;
}

export default function getModalStates({
  isLoading,
  isCompleted,
  errorCount,
  storesCount,
}: GetModalStatesProps): string[] {
  let TITLE = 'STORE_DETAILS_PAGE.CONFIRM_MODAL_TITLE';
  let DESCRIPTION = 'STORE_DETAILS_PAGE.CONFIRM_MODAL_DESCRIPTION';
  let BACK_BUTTON = 'ACTION_BUTTONS.GO_BACK';
  let CONFIRM_BUTTON = 'ACTION_BUTTONS.CONFIRM';

  if (isLoading) {
    TITLE = 'STORE_DETAILS_PAGE.CONFIRM_MODAL_PROCESSING_TITLE';
    DESCRIPTION = 'STORE_DETAILS_PAGE.CONFIRM_MODAL_PROCESSING_DESCRIPTION';
    BACK_BUTTON = 'ACTION_BUTTONS.CANCEL';
  }

  if (errorCount > 0 || isCompleted) {
    TITLE = 'STORE_DETAILS_PAGE.CONFIRM_MODAL_PROCESSED_TITLE';
    DESCRIPTION = 'STORE_DETAILS_PAGE.CONFIRM_MODAL_PROCESSED_DESCRIPTION';
    BACK_BUTTON = 'ACTION_BUTTONS.CLOSE';
    CONFIRM_BUTTON = isCompleted ? 'ACTION_BUTTONS.FINISH' : 'ACTION_BUTTONS.TRY_AGAIN_FAILED';
  }

  if (errorCount === storesCount && errorCount > 0) {
    DESCRIPTION = 'STORE_DETAILS_PAGE.CONFIRM_MODAL_ALL_FAILED_DESCRIPTION';
  }

  return [TITLE, DESCRIPTION, BACK_BUTTON, CONFIRM_BUTTON];
}
