import { Select, SelectProps, Tooltip } from '@hexa-ui/components';
import { Info } from '@hexa-ui/icons';
import { Field, FieldProps } from 'formik';
import omit from 'lodash/omit';
import { Label, LabelContainer } from './FormikSelect.styles';

interface SelectOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export type FormikSelectProps = SelectProps & {
  name: string;
  options: SelectOption[];
  showError?: boolean;
  tooltip?: string;
};

function FormikSelect({
  name,
  options,
  onChange,
  onBlur,
  label,
  tooltip,
  showError = true,
  ...selectProps
}: FormikSelectProps): JSX.Element {
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const { error, touched } = meta;
        const shouldShowError = Boolean(error) && (form.submitCount > 0 || (showError && touched));

        const fieldWithoutMultiple = omit(field, 'multiple');

        return (
          <div>
            {label && (
              <LabelContainer isDisabled={!!selectProps.disabled}>
                <Label htmlFor={name}>{label}</Label>
                {tooltip && (
                  <Tooltip text={tooltip} placement="top">
                    <Info size="medium" />
                  </Tooltip>
                )}
              </LabelContainer>
            )}
            <Select.Root
              aria-label={name}
              {...selectProps}
              error={shouldShowError ? error : ''}
              {...fieldWithoutMultiple}
              onChange={(value) => {
                if (!value) return;
                onChange?.(value);
                form.setFieldValue(field.name, value);
              }}
              onBlur={(event) => {
                onBlur?.(event);
                form.setFieldTouched(field.name, true);
              }}
            >
              {options.map((option) => (
                <Select.Option
                  key={option.value}
                  aria-label={option.label}
                  value={option.value}
                  disabled={option.disabled ?? false}
                >
                  {option.label}
                </Select.Option>
              ))}
            </Select.Root>
          </div>
        );
      }}
    </Field>
  );
}

export default FormikSelect;
