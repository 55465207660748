import { Heading, Paragraph } from '@hexa-ui/components';
import { Header } from 'components/Header/Header';
import ImageComponent from 'components/ImageComponent/ImageComponent';
import Toggle from 'components/Toggle/Toggle';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { GetStoreDetailsResponse } from 'services/stores';
import {
  ActionsContainer,
  Container,
  Thumbnail,
  ThumbnailsContainer,
} from './StoreSettingsPageView.styles';
import ThumbnailModal from './components/ThumbnailModal/ThumbnailModal';

interface StoreSettingsPageViewProps {
  store?: GetStoreDetailsResponse;
}

export interface Thumbnail {
  id: number;
  label: string;
  image: string;
  isEditable: boolean;
  status?: boolean;
  disabled?: boolean;
}

/**
 * @deprecated
 */
export default function StoreSettingsPageView({ store }: StoreSettingsPageViewProps): JSX.Element {
  const { country, id } = useParams<{ country: string; id: string }>();

  const { formatMessage } = useIntl();

  const breadcrumbItems = [
    {
      text: formatMessage({ id: 'BREADCRUMB.COMPANY_PROFILE_SETUP' }),
      to: '/company-management',
      isCurrentPage: false,
    },
    {
      text: formatMessage({ id: 'BREADCRUMB.STORES' }),
      to: '/company-management/stores',
      isCurrentPage: false,
    },
    {
      text: formatMessage({ id: 'BREADCRUMB.STORE_DETAILS' }),
      to: `/company-management/stores/${country}/${id}`,
      isCurrentPage: false,
    },
    {
      text: formatMessage({ id: 'BREADCRUMB.MANAGE_SETTINGS' }),
      to: `/company-management/stores/${country}/${id}/settings`,
      isCurrentPage: true,
    },
  ];

  const thumbnails: Thumbnail[] = [
    {
      id: 1,
      label: formatMessage({ id: 'STORE_SETTINGS_PAGE.THUMBNAILS_STORE_LOGO_LABEL' }),
      image: store?.image ?? '',
      isEditable: false,
    },
    {
      id: 2,
      label: formatMessage({ id: 'STORE_SETTINGS_PAGE.THUMBNAILS_PROMOTIONS_LABEL' }),
      image: store?.configurations?.promotions?.image ?? '',
      isEditable: true,
      status: false,
      disabled: true,
    },
    {
      id: 3,
      label: formatMessage({ id: 'STORE_SETTINGS_PAGE.THUMBNAILS_MISCELLANEOUS_LABEL' }),
      image: store?.configurations?.miscellaneous?.image ?? '',
      isEditable: true,
      status: false,
      disabled: true,
    },
  ];

  return (
    <Container data-testid="store-settings">
      <Header breadcrumbItems={breadcrumbItems}>
        <Paragraph colortype="secondary">
          {formatMessage({ id: 'STORE_SETTINGS_PAGE.THUMBNAILS_SUBTITLE_OLD' })}
        </Paragraph>

        <ThumbnailsContainer elevated="small" border="medium">
          {thumbnails.map((thumbnail) => {
            return (
              <Thumbnail key={`thumbnail-${thumbnail.id}`}>
                <Heading size="H5">{thumbnail.label}</Heading>
                <ImageComponent src={thumbnail.image} size="204px" />

                <ActionsContainer>
                  {thumbnail.isEditable && (
                    <Toggle checked={!!thumbnail.status} disabled={thumbnail.disabled} />
                  )}
                  <ThumbnailModal thumbnail={thumbnail} />
                </ActionsContainer>
              </Thumbnail>
            );
          })}
        </ThumbnailsContainer>
      </Header>
    </Container>
  );
}
