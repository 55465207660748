import { useMutation } from '@tanstack/react-query';
import Api from 'Api/Api';
import { COMPANY_MANAGEMENT_BFF_BASE_URL } from 'config/constants';
import { EndpointsToLog } from 'features/log/log';
import { useMemo } from 'react';
import { UpdateVendorStoresParams } from 'services/company';

export interface VendorStoreToUpdate {
  id: string;
  associated: boolean;
}

interface PatchAllVendorsParams {
  country?: string;
  vendorId?: string;
  data: VendorStoreToUpdate[];
  logData: UpdateVendorStoresParams['logData'];
}

export interface PatchAllVendorsResponse {
  storeId: string;
  error: boolean;
}

const patchVendor = async ({
  country,
  vendorId,
  data,
  logData,
}: PatchAllVendorsParams): Promise<PatchAllVendorsResponse> => {
  const url = `${COMPANY_MANAGEMENT_BFF_BASE_URL}/v1/vendors/${vendorId}/stores`;

  const headers = {
    country: country?.toUpperCase(),
    log: EndpointsToLog.updateVendorStores,
    logExtraData: JSON.stringify(logData),
  };

  return Api.patch({ url, config: { headers }, body: data }).then((response) => ({
    storeId: data[0].id,
    error: !!response.hasError,
  }));
};

export interface UsePatchAllVendorsResult {
  isLoading: boolean;
  mutate: (data: PatchAllVendorsParams['data']) => Promise<PatchAllVendorsResponse[]>;
}

export interface UsePatchAllVendorsParams {
  country: string;
  vendorId: string;
  logData: UpdateVendorStoresParams['logData'];
}

const patchAllVendors = async ({
  country,
  vendorId,
  data,
  logData,
}: PatchAllVendorsParams): Promise<PatchAllVendorsResponse[]> => {
  return Promise.all(
    data.map(({ id, associated }) =>
      patchVendor({ country, vendorId, data: [{ id, associated }], logData })
    )
  );
};

export const usePatchAllVendors = ({
  country,
  vendorId,
  logData,
}: UsePatchAllVendorsParams): UsePatchAllVendorsResult => {
  const {
    isLoading,
    data: storesData,
    mutateAsync,
  } = useMutation({
    mutationFn: (data: PatchAllVendorsParams['data']) =>
      patchAllVendors({ country, vendorId, data, logData }),
  });

  return useMemo(
    () => ({
      mutate: mutateAsync,
      isLoading,
    }),
    [mutateAsync, isLoading, storesData]
  );
};
