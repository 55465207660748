import { Copy } from '@hexa-ui/icons';
import { styled } from 'theme';

export const CopyIcon = styled(Copy);

export const Container = styled('div', {
  display: 'flex',
});

export const CopySection = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: '$2',
  borderRadius: '$1',
  cursor: 'pointer',
  padding: '$1',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: '$interfaceSurfaceSecondary',
  },
});
