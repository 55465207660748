import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
} from '@tanstack/react-query';
import Api from 'Api/Api';
import { useMemo } from 'react';

import { GetStoresParams, GetStoresResponse, getStoresQuery } from 'services/stores';

export const getStores = async (params: GetStoresParams) => {
  const { url, ...config } = getStoresQuery(params);

  const result = await Api.get<GetStoresResponse>({ url, config });

  return result.data;
};

export interface UseGetStoresResult {
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
  error?: unknown;
  data?: GetStoresResponse['content'];
  hasNextPage: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<GetStoresResponse, unknown>>;
}

export interface UseGetStoresParams {
  country?: string;
}

export const useGetStores = ({ country }: UseGetStoresParams): UseGetStoresResult => {
  const { isLoading, isError, error, data, isFetching, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: ['get-stores'],
      queryFn: ({ pageParam = 0 }) =>
        getStores({ country: country!, page: pageParam, pageSize: 10 }),
      getNextPageParam: (lastPage, allPages) => {
        const hasNextPage = lastPage?.pagination?.page < lastPage.pagination?.totalPages;
        const totalPagesLength = allPages?.length;
        return hasNextPage ? totalPagesLength : undefined;
      },
      refetchOnWindowFocus: false,
      enabled: !!country,
    });

  return useMemo(
    () => ({
      isFetching,
      isLoading,
      isError,
      error,
      fetchNextPage,
      hasNextPage: hasNextPage!,
      data: data?.pages.map((page) => page.content).flat(),
    }),
    [isFetching, isLoading, isError, error, data, fetchNextPage, hasNextPage]
  );
};
