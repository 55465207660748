import { ThemeOptions } from '@material-ui/core';

export const palette = {
  primary: {
    main: '#000',
  },
};

export const typography = {
  fontFamily: '"Work Sans", "Helvetica Neue", Helvetica, Arial, "sans-serif"',
  body1: {
    letterSpacing: 0,
  },
  body2: {
    letterSpacing: 0,
  },
};

export const overrides = {
  MuiFormLabel: {
    root: {
      color: '#000000',
      fontWeight: 'bold',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      lineHeight: '20px',
      marginBottom: 4,
      marginTop: 8,
      width: '100%',
    },
  },
  MuiOutlinedInput: {
    adornedStart: { paddingLeft: '16px' },
    root: {
      marginBottom: 16,
    },
  },
  MuiTypography: {
    h4: {
      fontSize: 32,
      lineHeight: '40px',
      letterSpacing: 0,
    },
    h5: {
      fontSize: 25,
      fontWeight: 600,
      lineHeight: '20px',
    },
    h6: {
      fontSize: 21,
      fontWeight: 600,
      lineHeight: '20px',
    },
    subtitle1: {
      lineHeight: '22px',
      marginTop: '8px',
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: 14,
      padding: '12px 16px',
    },
  },
  MuiTableCell: {
    head: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: '20px',
      paddingTop: 18,
      paddingBottom: 18,
    },
    body: {
      fontSize: 16,
      lineHeight: '16px',
    },
  },
  MuiTableRow: {
    root: {
      '& th:first-child, td:first-child': {
        paddingLeft: 32,
      },
      '& th:last-child, td:last-child': {
        paddingRight: 32,
      },
    },
    hover: {
      height: 66,
      '&:hover': {
        backgroundColor: '#EEE !important',
      },
    },
  },
  MuiDialogActions: {
    spacing: {
      '& > :not(:first-child)': {
        marginLeft: '16px',
      },
    },
  },
  MuiButton: {
    root: {
      padding: '8px 24px',
      fontWeight: 600,
      lineHeight: '24px',
      fontSize: 16,
      textAlign: 'center',
      minWidth: '100px',
    },
    text: {
      padding: '8px 16px',
    },
    outlinedPrimary: {
      borderColor: '#000',
      boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
    },
  },
  MuiRadio: {
    root: {
      color: '#000',
    },
  },
  MuiStepConnector: {
    alternativeLabel: {
      top: '15px',
    },
    line: {
      borderColor: '#000',
      margin: '0 4px',
    },
  },
  MuiStepIcon: {
    text: {
      fontWeight: 600,
      fontSize: '18px',
      /** for better understanding of why this was implemented, check: https://drafts.csswg.org/css-inline/#propdef-baseline-shift */
      '-moz-transform': 'translate(0px, 2px)',
      baselineShift: '-2px',
    },
  },
  MuiStepLabel: {
    label: {
      color: '#000',
    },
    labelContainer: {
      width: '90%',
    },
  },
} as ThemeOptions['overrides'];
