import { styled } from 'theme';

export const ErrorContainer = styled('div', {
  display: 'flex',
  height: 'calc(100vh - 56px)',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  '& [class*="error-403"]': {
    width: '100%',
    maxWidth: '450px',
    height: 'auto',
    marginBottom: '$4',
  },
});
