import { useIntl } from 'react-intl';

import { Settings } from '@hexa-ui/icons';
import { useUserMetadata } from 'admin-portal-shared-services';
import { SEGMENT_ANALYTICS_APP_NAME, SEGMENT_ANALYTICS_PERMISSION_GROUP } from 'config/constants';
import useTrackingSegment from 'hooks/useTrackingSegment';
import { useNavigate, useParams } from 'react-router-dom';
import { GetStoreDetailsResponse } from 'services/stores';
import { Button } from './ManageSettings.styles';

export interface ManageSettingsProps {
  store: GetStoreDetailsResponse;
}

const ManageSettingsIcon = () => <Settings size="medium" />;

const ManageSettings = ({ store }: ManageSettingsProps) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { data } = useUserMetadata();
  const analytics = useTrackingSegment();
  const { country, id } = useParams<{ country: string; id: string }>();

  const eventElementClicked = () => {
    analytics.track('Element Clicked', {
      appDisplayName: SEGMENT_ANALYTICS_APP_NAME,
      isCoreEvent: false,
      pageLabel: 'Store Details',
      pageName: 'Store Details',
      pageUrl: `/company-management/stores/${country}/${id}`,
      userPermissionGroup: data?.authorization?.groups?.filter((value) =>
        value.startsWith(SEGMENT_ANALYTICS_PERMISSION_GROUP)
      ),
      elementLocationType: 'HEADER',
      elementLocationName: 'HEADER',
      elementLabel: 'Manage Settings',
      elementName: 'Manage Settings',
      elementType: 'BUTTON',
      storeStatus: store.status,
      storeId: store.id,
      storeName: store.name,
      storeDefaultSeller: store.defaultVendor?.name,
    });
  };

  const handleClick = () => {
    navigate('settings');
    eventElementClicked();
  };

  return (
    <Button
      data-testid="manage-settings-button"
      icon={ManageSettingsIcon}
      size="medium"
      leading
      onClick={handleClick}
    >
      {formatMessage({ id: 'STORE_DETAILS_PAGE.MANAGE_SETTINGS' })}
    </Button>
  );
};

export default ManageSettings;
