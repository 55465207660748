import { IntlConfigType } from './format.types';

export const formatRequestorInfo = (
  userName: string,
  creationDate: Date,
  intlConfig: IntlConfigType
) => {
  return `${userName} ${intlConfig.intl.formatDate(creationDate, intlConfig.dateOptions)}`;
};

export const formatString = (str?: string) => {
  if (!str) return '-';
  return str;
};
