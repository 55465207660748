import { Card, Paragraph } from '@hexa-ui/components';
import { styled } from 'theme';

export const SectionCard = styled(Card, {
  padding: '$6 $6 $10 $6',
});

export const FlexContainer = styled('div', {
  display: 'flex',
  gap: '$12',
  marginTop: '$8',
  flexWrap: 'wrap',
  '@desktopSmall': {
    flexWrap: 'nowrap',
  },
});

export const GridContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
  gridGap: '$8',
  width: '100%',
  marginBottom: 'auto',
  '@desktopLarge': {
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    '& > :last-child': {
      gridColumn: '1 / span 2',
    },
  },
});

export const Description = styled(Paragraph, {
  marginTop: '$2',
});
