import {
  Button,
  ButtonProps,
  Dialog,
  Heading,
  Paragraph,
  Select,
  SkeletonLoader,
} from '@hexa-ui/components';
import { useUserMetadata } from 'admin-portal-shared-services';
import { store } from 'app/store';
import ImageComponent from 'components/ImageComponent/ImageComponent';
import InfiniteSelect from 'components/InfiniteSelect/InfiniteSelect';
import { SEGMENT_ANALYTICS_APP_NAME, SEGMENT_ANALYTICS_PERMISSION_GROUP } from 'config/constants';
import { useGetStores, useGetStoresByVendorId } from 'hooks/queries';
import useTrackingSegment from 'hooks/useTrackingSegment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Store, Vendor } from 'services/stores';
import { usePatchAllVendors } from '../../../../hooks/queries/usePatchAllVendors';
import { CompanyCard, CompanyInfo, WrapperButtons } from './AssociateModal.styles';
import { ConfirmModal } from './components/ConfirmModal/ConfirmModal';

export interface AssociateModalProps {
  isOpen: boolean;
  vendor: Vendor | null;
  onClose: () => void;
  onOpen: () => void;
  country: string;
}

export interface IActionButtons {
  title: string;
  variant: ButtonProps['variant'];
  disabled?: boolean;
  type: 'button' | 'submit';
  action: () => void;
  isLoading?: boolean;
}

export type SelectedStore = Store & {
  associated: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  isLoading?: boolean;
};

export function AssociateModal({
  isOpen,
  onClose,
  country,
  onOpen,
  vendor,
}: Readonly<AssociateModalProps>) {
  const { formatMessage } = useIntl();
  const analytics = useTrackingSegment();
  const { id } = useParams<{ id: string }>();
  const [selectedStores, setSelectedStores] = useState<SelectedStore[]>([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { data: dataUserMetaData } = useUserMetadata();

  const {
    data = [],
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingAllStores,
  } = useGetStores({ country });

  const {
    data: assignedStores = [],
    isLoading: isLoadingAssignedStores,
    isFetching: isFetchingAssignedStores,
  } = useGetStoresByVendorId({
    country,
    vendorId: vendor?.id,
  });

  const { isLoading: isLoadingAssign, mutate } = usePatchAllVendors({
    country,
    vendorId: vendor?.id ?? '',
    logData: {
      vendorId: vendor?.id ?? '',
      vendorName: vendor?.name ?? '',
    },
  });

  const modifiedStores = useMemo(
    () =>
      selectedStores.filter(
        (store) =>
          !store.associated ||
          !assignedStores.some((assignedStore) => assignedStore.id === store.id)
      ),
    [selectedStores]
  );

  const isLoading = useMemo(
    () => isLoadingAssignedStores || isFetchingAssignedStores || isLoadingAllStores,
    [isLoadingAssignedStores, isFetchingAssignedStores, isLoadingAllStores]
  );

  const handleSubmit = useCallback(async () => {
    await mutate(modifiedStores.filter((store) => !store.isSuccess)).then((response) => {
      setSelectedStores((currentStores) =>
        currentStores.map((store) => {
          const result = response.find((result) => result?.storeId === store.id);
          if (!result) return store;

          return {
            ...store,
            isLoading: false,
            isSuccess: !result.error,
            isError: !!result.error,
          };
        })
      );
    });
  }, [selectedStores]);

  useEffect(() => {
    if (isLoading || !assignedStores?.length || selectedStores?.length) return;

    const stores = assignedStores.map((store) => ({
      ...store,
      associated: true,
      isError: false,
      isSuccess: false,
      isLoading: false,
    }));

    setSelectedStores(stores);
  }, [assignedStores, isLoading, selectedStores]);

  useEffect(() => {
    if (!isLoadingAssign) return;

    setSelectedStores((currentStores) =>
      currentStores?.map((store) => {
        if (store.isSuccess) return store;

        return {
          ...store,
          isLoading: isLoadingAssign,
        };
      })
    );
  }, [isLoadingAssign]);

  const elementClickedEvent = () => {
    if (!store) return;
    if (!vendor) return;
    if (!dataUserMetaData) return;

    analytics.track('Element Clicked', {
      appDisplayName: SEGMENT_ANALYTICS_APP_NAME,
      isCoreEvent: true,

      pageLabel: 'Store Details',
      pageName: 'Store Details',
      pageUrl: `/company-management/stores/${country}/${id}`,
      userPermissionGroup: dataUserMetaData?.authorization?.groups?.filter((value) =>
        value.startsWith(SEGMENT_ANALYTICS_PERMISSION_GROUP)
      ),
      vendorCountry: country,
      vendorDisplayName: vendor?.name,
      vendorId: vendor?.id,
      vendorServiceModel: vendor?.serviceModel,
      vendorTierName: vendor?.tier,
      elementLocationType: 'MODAL',
      elementLocationName: 'MODAL',
      elementLabel: 'Confirm',
      elementName: 'Confirm',
      elementType: 'BUTTON',
      storeDefaultSeller: vendor?.name,
    });
  };

  const handleCloseConfirmModal = () => {
    onOpen();
    setIsConfirmModalOpen(false);
  };

  const handleClose = () => {
    setSelectedStores([]);
    onClose();
    setIsConfirmModalOpen(false);
  };

  const handleSelectStores = useCallback(
    (values: string[]) => {
      const newSelectedStores = selectedStores
        .filter(
          (store) =>
            values.includes(store.id) ||
            assignedStores.some((assignedStore) => assignedStore.id === store.id)
        )
        .map((store) => {
          const isAssociated = values.includes(store.id);

          return { ...store, associated: isAssociated };
        });

      const stores = values.reduce((acc, storeId) => {
        const isAlreadySelected = acc.map((store) => store.id).includes(storeId);

        if (isAlreadySelected) return acc;

        const store = data.find((store) => store.id === storeId) as Store;

        acc.push({
          ...store,
          associated: values.includes(store?.id),
          isError: false,
          isSuccess: false,
          isLoading: false,
        });

        return acc;
      }, newSelectedStores);

      setSelectedStores(stores);
    },
    [data, selectedStores]
  );

  const handleConfirm = () => {
    onClose();
    setIsConfirmModalOpen(true);
  };

  const isDataTouched = useMemo(() => {
    if (isLoading) return false;
    const allOriginalDataIncluded = selectedStores?.every((store) =>
      assignedStores.some(
        (assignedStore) => assignedStore?.id === store?.id && store?.associated === true
      )
    );
    const hasSameLength = selectedStores.length === assignedStores.length;

    return !(hasSameLength && allOriginalDataIncluded);
  }, [selectedStores, assignedStores, isLoading]);

  const renderActionButtons = useMemo(
    () => (
      <WrapperButtons>
        <Button
          size="medium"
          variant="secondary"
          onClick={handleClose}
          data-testid="associate-modal-close"
        >
          {isDataTouched
            ? formatMessage({ id: 'ACTION_BUTTONS.GO_BACK' })
            : formatMessage({ id: 'ACTION_BUTTONS.CANCEL' })}
        </Button>

        <Button
          size="medium"
          variant="primary"
          onClick={handleConfirm}
          disabled={isLoading || !isDataTouched}
        >
          {formatMessage({ id: 'ACTION_BUTTONS.CONFIRM' })}
        </Button>
      </WrapperButtons>
    ),
    [isLoading, isDataTouched, selectedStores, handleClose, handleConfirm]
  );

  const renderedOptions = useMemo(() => {
    const filteredData = data?.filter(
      (store) => !assignedStores?.some((selectedStore) => selectedStore.id === store.id)
    );

    return [...assignedStores, ...filteredData].map((store) => {
      return (
        <Select.Option
          value={store.id}
          key={store.id}
          disabled={vendor?.id === store.defaultVendorId}
          data-testid="select-option"
        >
          {store.name}
        </Select.Option>
      );
    });
  }, [data, assignedStores]);

  const isSelectDisabled = useMemo(
    () => !data.length && !assignedStores.length,
    [data, assignedStores]
  );

  return (
    <>
      <Dialog.Root
        title={
          <Heading size="H2">
            {formatMessage({ id: 'STORE_DETAILS_PAGE.ASSOCIATE_MODAL_TITLE' })}
          </Heading>
        }
        open={isOpen}
        onClose={handleClose}
        actions={renderActionButtons}
      >
        <Paragraph css={{ marginBottom: '32px' }}>
          {formatMessage(
            { id: 'STORE_DETAILS_PAGE.ASSOCIATE_MODAL_SUBTITLE' },
            { vendorName: vendor?.name }
          )}
        </Paragraph>

        <CompanyCard>
          <ImageComponent src={vendor?.image as string} size="64" />
          <CompanyInfo>
            <Paragraph weight="semibold">{vendor?.name}</Paragraph>
            <Paragraph>{vendor?.governmentId}</Paragraph>
          </CompanyInfo>
        </CompanyCard>

        {!isLoading ? (
          <InfiniteSelect
            fetchNextPage={fetchNextPage}
            dataTestId="associate-modal-select"
            isFirstLoading={false}
            hasNextPage={hasNextPage}
            selectProps={{
              multiple: true,
              onChange: handleSelectStores,
              value: selectedStores.filter((store) => store.associated).map((store) => store.id),
              label: formatMessage({ id: 'STORE_DETAILS_PAGE.ASSOCIATED_STORE_LABEL' }),
              disabled: isSelectDisabled,
              placeholder: isSelectDisabled
                ? formatMessage({ id: 'STORE_DETAILS_PAGE.NO_STORES_AVAILABLE_PLACEHOLDER' })
                : undefined,
            }}
          >
            {renderedOptions}
          </InfiniteSelect>
        ) : (
          <SkeletonLoader data-testid="loader" width="650px" height="72px" />
        )}
      </Dialog.Root>

      <ConfirmModal
        stores={modifiedStores}
        country={country}
        isOpen={isConfirmModalOpen}
        isLoading={isLoadingAssign}
        onClose={handleClose}
        onGoback={handleCloseConfirmModal}
        onConfirm={() => {
          handleSubmit();
          elementClickedEvent();
        }}
        vendorName={vendor?.name ?? ''}
      />
    </>
  );
}
