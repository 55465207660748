import { styled } from 'theme';

const TableWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  tr: {
    borderBottom: '0 !important',
  },
  'tr:nth-child(even)': {
    backgroundColor: '#F2F2F2',
  },
  '& > div:last-child > div': {
    overflow: 'visible',
  },
});

const Header = styled('div', {
  display: 'flex',
  width: '100%',
  padding: '24px 16px',
  justifyContent: 'space-between',
  borderRadius: '16px 16px 0px 0px',
  backgroundColor: '$neutral0',
});

const Styled = { TableWrapper, Header };

export default Styled;
