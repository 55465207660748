import { HandleFileUpload } from '@content-shared-components/image-uploader';
import { Button } from '@hexa-ui/components';
import { useUserMetadata } from 'admin-portal-shared-services';
import { Error404 } from 'components/Error/404/404';
import { Header } from 'components/Header/Header';
import { SEGMENT_ANALYTICS_APP_NAME, SEGMENT_ANALYTICS_PERMISSION_GROUP } from 'config/constants';
import { FormikProvider } from 'formik';
import { useSettingsForm } from 'hooks/forms';
import { useFeatureToggles } from 'hooks/useFeatureToggles';
import useTrackingSegment from 'hooks/useTrackingSegment';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { emptySplitApi, useGetStoreDetailsQuery } from 'services/baseApi';
import { Loading } from 'theme/globalStyles';
import StoreSettingsPageView from './StoreSettingsPageView';
import StoreSettingsForm from './components/StoreSettingsForm/StoreSettingsForm';

export default function StoreSettingsPage(): JSX.Element {
  const { country, id } = useParams<{ country: string; id: string }>();
  const { formatMessage } = useIntl();
  const { showManageStores } = useFeatureToggles();
  const dispatch = useDispatch();
  const imageRef = useRef<HandleFileUpload>(null);
  const promotionsRef = useRef<HandleFileUpload>(null);
  const miscellaneousRef = useRef<HandleFileUpload>(null);
  const { data } = useUserMetadata();
  const analytics = useTrackingSegment();

  const {
    data: store,
    isLoading: isLoadingStore,
    isFetching: isFetchingStore,
  } = useGetStoreDetailsQuery({ country: country!, id: id! }, { skip: !country });

  const { form, onSubmit, formChanged } = useSettingsForm({
    store,
    country: country!,
    imageRef,
    promotionsRef,
    miscellaneousRef,
  });

  const breadcrumbItems = [
    {
      text: formatMessage({ id: 'BREADCRUMB.COMPANY_PROFILE_SETUP' }),
      to: '/company-management',
      isCurrentPage: false,
    },
    {
      text: formatMessage({ id: 'BREADCRUMB.STORES' }),
      to: '/company-management/stores',
      isCurrentPage: false,
    },
    {
      text: formatMessage({ id: 'BREADCRUMB.STORE_DETAILS' }),
      to: `/company-management/stores/${country}/${id}`,
      isCurrentPage: false,
    },
    {
      text: formatMessage({ id: 'BREADCRUMB.MANAGE_SETTINGS' }),
      to: `/company-management/stores/${country}/${id}/settings`,
      isCurrentPage: true,
    },
  ];

  useEffect(() => {
    return () => {
      dispatch(emptySplitApi.util.resetApiState());
    };
  }, []);

  useEffect(() => {
    if (!store) return;
    if (!data) return;

    analytics.track('Page Viewed', {
      appName: SEGMENT_ANALYTICS_APP_NAME,
      isCoreEvent: false,
      pageLabel: 'Manage Settings',
      pageName: 'Manage Settings',
      pageUrl: `/company-management/stores/${country}/${id}/settings`,
      userPermissionGroup: data?.authorization?.groups?.filter((value) =>
        value.startsWith(SEGMENT_ANALYTICS_PERMISSION_GROUP)
      ),
      vendor_country: country,
      vendorDisplayName: store.defaultVendor?.name,
      vendorId: store.defaultVendor?.id,
      storeId: store.id,
      storeCountry: country,
      storeName: store.name,
    });
  }, [store, data, country, id]);

  const eventElementClicked = () => {
    analytics.track('Element Clicked', {
      appDisplayName: SEGMENT_ANALYTICS_APP_NAME,
      isCoreEvent: true,
      pageLabel: 'Manage Settings',
      pageName: 'Manage Settings',
      pageUrl: `/company-management/stores/${country}/${id}/settings`,
      userPermissionGroup: data?.authorization?.groups?.filter((value) =>
        value.startsWith(SEGMENT_ANALYTICS_PERMISSION_GROUP)
      ),
      storeId: store?.id,
      storeCountry: country,
      storeName: store?.name,
      elementLocationType: 'Header',
      elementLocationName: 'Header',
      elementLabel: 'Save Changes',
      elementName: 'Save Changes',
      elementType: 'BUTTON',
      storeDefaultSeller: store?.defaultVendor?.name,
      storeStatus: store?.status,
    });
  };

  const handleClick = () => {
    eventElementClicked();
    onSubmit();
  };

  if (isLoadingStore) return <Loading />;

  if (!isLoadingStore && !isFetchingStore && !store) return <Error404 />;

  return showManageStores ? (
    <Header
      breadcrumbItems={breadcrumbItems}
      actionButtons={
        <Button
          data-testid="submit-store-settings"
          onClick={handleClick}
          isLoading={form.isSubmitting}
          disabled={!formChanged || isFetchingStore || !form.isValid}
        >
          {formatMessage({ id: 'STORE_SETTINGS_PAGE.SAVE_CHANGES_BUTTON' })}
        </Button>
      }
    >
      <FormikProvider value={form}>
        <StoreSettingsForm
          defaultVendor={store?.defaultVendor}
          imageRef={imageRef}
          promotionsRef={promotionsRef}
          miscellaneousRef={miscellaneousRef}
        />
      </FormikProvider>
    </Header>
  ) : (
    <StoreSettingsPageView store={store} />
  );
}
