import { useAuthenticationService } from 'admin-portal-shared-services';
import { Error403 } from 'components/Error/403/403';
import { Error404 } from 'components/Error/404/404';
import { usePermissions } from 'hooks/usePermissions';
import StoreDetailsPage from 'pages/StoreDetailsPage/StoreDetailsPage';
import StoreSettingsPage from 'pages/StoreSettingsPage/StoreSettingsPage';
import { Suspense, lazy } from 'react';
import { redirect } from 'react-router-dom';
import { Loading } from 'theme/globalStyles';

const HomePage = lazy(() => import('./pages/HomePage/HomePage'));

export const useGetRoutes = () => {
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();
  const { canAccessStoreManagement, canManageStores } = usePermissions();

  if (!canAccessStoreManagement) {
    return [
      {
        path: '*',
        element: <Error403 />,
      },
    ];
  }

  const routes = [
    {
      index: true,
      path: '/company-management/stores/:country?',
      loader: async ({ params }: { params: { country?: string } }) => {
        if (!params.country) {
          return redirect(`/company-management/stores/${userCountry}`);
        }
        return null;
      },
      element: (
        <Suspense fallback={<Loading data-testid="router-loading-id" />}>
          <HomePage />
        </Suspense>
      ),
    },
    {
      path: '/company-management/stores/:country/:id',
      element: (
        <Suspense fallback={<Loading data-testid="router-loading-id" />}>
          <StoreDetailsPage />
        </Suspense>
      ),
    },
    {
      path: '/company-management/stores/:country/:id/settings',
      element: (
        <Suspense fallback={<Loading data-testid="router-loading-id" />}>
          {canManageStores ? <StoreSettingsPage /> : <Error403 />}
        </Suspense>
      ),
    },
    {
      path: '*',
      element: <Error404 />,
    },
  ];

  return routes;
};
