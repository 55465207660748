// InfiniteSelect.styles.ts
import { Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const MoreItemsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  padding: '$4',

  variants: {
    hasNextPage: {
      false: {
        height: 0,
        padding: 0,
      },
    },
  },
});

export const FirstLoadingContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
});

export const LoadingLabel = styled(Paragraph, {
  lineHeight: '$3',
});

export const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  button: {
    marginBottom: '2px',
  },
});
