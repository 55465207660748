import { HandleFileUpload } from '@content-shared-components/image-uploader';
import { RefObject } from 'react';
import { StoreDetails } from 'services/stores';
import StoreInformation from '../StoreInformation/StoreInformation';
import Thumbnails from '../Thumbnails/Thumbnails';
import { FormContainer } from './StoreSettingsForm.styles';

export interface StoreSettingsFormProps {
  defaultVendor?: StoreDetails['defaultVendor'];
  imageRef: RefObject<HandleFileUpload>;
  promotionsRef: RefObject<HandleFileUpload>;
  miscellaneousRef: RefObject<HandleFileUpload>;
}

function StoreSettingsForm({
  defaultVendor,
  imageRef,
  promotionsRef,
  miscellaneousRef,
}: StoreSettingsFormProps): JSX.Element {
  return (
    <FormContainer data-testid="settings-form">
      <StoreInformation defaultVendor={defaultVendor} imageRef={imageRef} />
      <Thumbnails promotionsRef={promotionsRef} miscellaneousRef={miscellaneousRef} />
    </FormContainer>
  );
}

export default StoreSettingsForm;
