import { Error403 as HexaError403 } from '@hexa-ui/components';
import { Error403Props } from '@hexa-ui/components/dist/declarations/src/lib/error/403/Error403';
import { ErrorContainer } from './403.styles';

export function Error403(props: Error403Props): JSX.Element {
  const onClick = () => window.history.pushState(null, 'Home', '/');
  return (
    <ErrorContainer data-testid="error-403-page">
      <HexaError403 onButtonClick={onClick} {...props} />
    </ErrorContainer>
  );
}
