import { Radio, RadioProps } from '@hexa-ui/components';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Field, FieldProps } from 'formik';
import omit from 'lodash/omit';
import { Label, RadioGroup } from './FormikRadio.styles';

interface RadioOption {
  id: string;
  label: string;
  value: string | number;
}

export type FormikRadioProps = Omit<RadioProps, 'onChange'> & {
  name: string;
  options: RadioOption[];
  label?: string;
  margin?: CSSProperties['margin'];
  boolean?: boolean;
  onChange?: (value: string) => void;
};

function FormikRadio({
  name,
  options,
  onChange,
  label,
  boolean = false,
  margin = '$2 0',
  ...radioProps
}: FormikRadioProps): JSX.Element {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const fieldWithoutOnChange = omit(field, ['onChange', 'onBlur']);
        return (
          <div>
            {label && (
              <Label htmlFor={name} isDisabled={!!radioProps.disabled}>
                {label}
              </Label>
            )}
            <RadioGroup
              aria-label={name}
              {...radioProps}
              {...fieldWithoutOnChange}
              css={{ margin, ...radioProps.css }}
              onValueChange={(value) => {
                onChange?.(value);
                if (boolean) {
                  form.setFieldValue(field.name, Boolean(Number(value)));
                } else form.setFieldValue(field.name, value);
              }}
            >
              {options.map((option) => {
                const normalizedOptionValue = boolean
                  ? Boolean(Number(option.value))
                  : option.value;
                return (
                  <Radio.Item
                    data-testid={`${option.value}-option`}
                    key={option.id}
                    id={option.id}
                    label={option.label}
                    value={option.value as string}
                    checked={field.value === normalizedOptionValue}
                    onBlur={field.onBlur}
                  />
                );
              })}
            </RadioGroup>
          </div>
        );
      }}
    </Field>
  );
}

export default FormikRadio;
