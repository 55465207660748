import { COMPANY_MANAGEMENT_BFF_BASE_URL } from 'config/constants';
import { EndpointsToLog } from 'features/log/log';
import { BaseQueryFnArgs } from 'services/baseApi';
import { UpdateVendorStoresParams } from './types';

export * from './types';

export const updateVendorStoresMutation = ({
  vendorId,
  country,
  stores,
  logData,
}: UpdateVendorStoresParams): BaseQueryFnArgs => ({
  url: `${COMPANY_MANAGEMENT_BFF_BASE_URL}/v1/vendors/${vendorId}/stores`,
  method: 'PATCH',
  data: stores,
  headers: {
    log: EndpointsToLog.updateVendorStores,
    logExtraData: JSON.stringify(logData),
    country: country.toUpperCase(),
  },
});
