/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Countries } from 'domains/Countries';
import { VendorStoreToUpdate } from 'hooks/queries/usePatchAllVendors';
import { EditMetadata } from 'hooks/useAuditLog';

export enum EndpointsToLog {
  updateVendorStores = 'updateVendorStores',
  updateStore = 'updateStore',
}

interface LogAssociateDissociate {
  vendorId: string;
  vendorName: string;
  stores: VendorStoreToUpdate[];
}

export interface LogUpdateVendorStores {
  endpoint: EndpointsToLog.updateVendorStores;
  data: LogAssociateDissociate;
  country: Countries;
}

interface LogEdit {
  storeId: string;
  fields: EditMetadata['fields'];
}

export interface LogUpdateStore {
  endpoint: EndpointsToLog.updateStore;
  data: LogEdit;
  country: Countries;
}

export type LogData =
  | LogUpdateVendorStores
  | LogUpdateStore
  | {
      endpoint: null;
      data: undefined;
      country?: Countries;
    };

const initialState = {
  endpoint: null,
  data: undefined,
  country: undefined,
} as LogData;

export const logSlice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    startLog: (state, { payload }: PayloadAction<LogData>) => {
      state.endpoint = payload.endpoint;
      state.data = payload.data;
      state.country = payload.country;
    },
    resetLog: (state) => {
      state.endpoint = null;
      state.data = undefined;
      state.country = undefined;
    },
  },
});

export default logSlice.reducer;
export const { startLog, resetLog } = logSlice.actions;
