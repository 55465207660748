import { HandleFileUpload } from '@content-shared-components/image-uploader';
import { Heading } from '@hexa-ui/components';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import FormikImage from 'components/FormikImage/FormikImage';
import FormikInput from 'components/FormikInput/FormikInput';
import FormikRadio from 'components/FormikRadio/FormikRadio';
import FormikSelect from 'components/FormikSelect/FormikSelect';
import { StoreStatus } from 'domains/StoreStatus';
import { useFormikContext } from 'formik';
import { SettingsForm } from 'hooks/forms/useSettingsForm';
import { RefObject, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useGetStoreAvailableVendorsQuery } from 'services/baseApi';
import { StoreDetails } from 'services/stores';
import { Description, FlexContainer, GridContainer, SectionCard } from './StoreInformation.styles';

interface StoreInformationProps {
  defaultVendor?: StoreDetails['defaultVendor'];
  imageRef: RefObject<HandleFileUpload>;
}

const LOADING_FEEBACK_DELAY = 250;

function StoreInformation({
  defaultVendor,
  imageRef,
}: Readonly<StoreInformationProps>): JSX.Element {
  const { country, id } = useParams<{ country: string; id: string }>();
  const { formatMessage } = useIntl();
  const toastService = useToast();

  const [isDefaultVendorDisabled, setIsDefaultVendorDisabled] = useState(false);

  const { initialValues, values, setFieldValue } = useFormikContext<SettingsForm>();

  const {
    data: availableVendors,
    isLoading,
    isFetching,
    isError,
  } = useGetStoreAvailableVendorsQuery(
    { country: country!, id: id!, storeStatus: values.status },
    { skip: !country }
  );

  const isLoadingAvailableVendors = isLoading || isFetching;

  const statusOptions = [
    {
      id: StoreStatus.ACTIVE,
      label: formatMessage({ id: `STATUS_COLUMN.${StoreStatus.ACTIVE}` }),
      value: StoreStatus.ACTIVE,
    },
    {
      id: StoreStatus.EXPERIMENTATION,
      label: formatMessage({ id: `STATUS_COLUMN.${StoreStatus.EXPERIMENTATION}` }),
      value: StoreStatus.EXPERIMENTATION,
    },
    {
      id: StoreStatus.INACTIVE,
      label: formatMessage({ id: `STATUS_COLUMN.${StoreStatus.INACTIVE}` }),
      value: StoreStatus.INACTIVE,
    },
  ];

  const defaultVendorOptions = useMemo(() => {
    if (isError)
      return [
        {
          label: defaultVendor?.name ?? '-',
          value: defaultVendor?.id ?? values.defaultVendorId,
        },
      ];
    if (!isDefaultVendorDisabled && availableVendors)
      return availableVendors.map((vendor) => ({
        label: vendor.name,
        value: vendor.id,
        disabled: !vendor.enabled,
      }));
    return [
      {
        label: formatMessage({
          id: 'STORE_SETTINGS_PAGE.STORE_INFORMATION_DEFAULT_SELLER_LOADING',
        }),
        value: values.defaultVendorId,
      },
    ];
  }, [availableVendors, isDefaultVendorDisabled, isError, values.defaultVendorId]);

  useEffect(() => {
    setIsDefaultVendorDisabled(true);
    if (!isLoadingAvailableVendors) {
      if (isError) {
        setFieldValue('defaultVendorId', initialValues.defaultVendorId);
        toastService.notify({
          message: formatMessage({ id: 'STORE_SETTINGS_PAGE.ERROR_LOADING_AVAILABLE_VENDORS' }),
          type: TypeToast.ERROR,
        });
      } else {
        setTimeout(() => {
          setFieldValue('defaultVendorId', initialValues.defaultVendorId);
          setIsDefaultVendorDisabled(false);
        }, LOADING_FEEBACK_DELAY);
      }
    }
  }, [values.status, isError, isLoadingAvailableVendors]);

  return (
    <SectionCard elevated="small" border="medium">
      <Heading size="H3">
        {formatMessage({ id: 'STORE_SETTINGS_PAGE.STORE_INFORMATION_TITLE' })}
      </Heading>
      <Description colortype="secondary" size="small">
        {formatMessage({ id: 'STORE_SETTINGS_PAGE.STORE_INFORMATION_SUBTITLE' })}
      </Description>
      <FlexContainer>
        <FormikImage formikRef={imageRef} name="image" />
        <GridContainer>
          <FormikInput
            name="name"
            width="100%"
            label={formatMessage({ id: 'STORE_SETTINGS_PAGE.STORE_INFORMATION_STORE_NAME' })}
          />
          <FormikSelect
            name="defaultVendorId"
            width="100%"
            label={formatMessage({ id: 'STORE_SETTINGS_PAGE.STORE_INFORMATION_DEFAULT_SELLER' })}
            tooltip={formatMessage({
              id: 'STORE_SETTINGS_PAGE.STORE_INFORMATION_DEFAULT_SELLER_TOOLTIP',
            })}
            disabled={isDefaultVendorDisabled}
            options={defaultVendorOptions}
          />
          <FormikRadio
            name="status"
            label={formatMessage({ id: 'STORE_SETTINGS_PAGE.STORE_INFORMATION_STORE_STATUS' })}
            options={statusOptions}
          />
        </GridContainer>
      </FlexContainer>
    </SectionCard>
  );
}

export default StoreInformation;
