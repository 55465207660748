// eslint-disable-next-line import/extensions
import { Breadcrumb } from '@hexa-ui/components';
import { useAppHeader } from 'admin-portal-shared-services';
import CountrySelector from 'components/CountrySelector/CountrySelector';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { HeaderActions, HeaderContainer, HeaderRowContainer, HeaderTitle } from './Header.styles';

export type BreadcrumbOptionFn = (event: React.MouseEvent<HTMLAnchorElement>) => void;
export interface BreadcrumbOptions {
  text: string;
  to: string;
  isCurrentPage: boolean;
}

export interface SelectOption {
  label: string;
  value: string;
}

export type HeaderProps = {
  children: React.ReactNode;
  breadcrumbItems: BreadcrumbOptions[];
  showCountrySelect?: boolean;
  actionButtons?: JSX.Element | JSX.Element[];
};

export function Header({
  showCountrySelect = false,
  breadcrumbItems,
  actionButtons,
  children,
}: Readonly<HeaderProps>): JSX.Element {
  const [items] = useState<BreadcrumbOptions[]>(breadcrumbItems);

  const currentPage = useMemo(() => items?.find((item) => item.isCurrentPage), [items]);

  const [, setAppHeaderConfig] = useAppHeader();

  useEffect(() => {
    setAppHeaderConfig({
      breadcrumbConfig: undefined,
      defaultCountry: undefined,
      countryOptions: [],
      countrySelect: false,
      optionalButtons: [],
      pageTitle: '',
      vendorSelect: false,
    });
  }, [setAppHeaderConfig]);

  return (
    <>
      <HeaderContainer data-testid="app-header">
        <HeaderRowContainer data-testid="app-header-first-line">
          <Breadcrumb.Root>
            <Breadcrumb.HomeItem asChild>
              <Link to="/" />
            </Breadcrumb.HomeItem>
            {items?.map(({ text, to, isCurrentPage }) => (
              <Breadcrumb.Item key={to} asChild isCurrentPage={isCurrentPage}>
                <Link to={to}>{text}</Link>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb.Root>
          <HeaderActions data-testid="selectors-container">
            {showCountrySelect && <CountrySelector />}
          </HeaderActions>
        </HeaderRowContainer>
        <HeaderRowContainer data-testid="app-header-second-line">
          <HeaderTitle size="H2">{currentPage?.text}</HeaderTitle>
          <HeaderActions data-testid="buttons-container">{actionButtons}</HeaderActions>
        </HeaderRowContainer>
      </HeaderContainer>
      {children}
    </>
  );
}
