import { LoadingBuzz } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { globalCss } from './stitches';

export const globalStyles = globalCss({
  'header#mfe-content-header > div h1': {
    display: 'block',
    height: 'auto',
    lineHeight: '40px',
    wordBreak: 'break-word',
  },
  'header#mfe-content-header.hideHeader': {
    display: 'none',
  },
});

export const Loading = styled(LoadingBuzz, {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
