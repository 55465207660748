import { Input, InputProps } from '@hexa-ui/components';
import { Field, FieldProps } from 'formik';
import { InputWrapper } from './FormikInput.styles';

export interface FormikInputProps extends Omit<InputProps, 'ref'> {
  name: string;
  showError?: boolean;
}

function FormikInput({
  name,
  showError = true,
  required = true,
  ...inputProps
}: FormikInputProps): JSX.Element {
  return (
    <Field name={name}>
      {({ field, form, meta }: FieldProps) => {
        const { error, touched } = meta;
        const shouldShowError = Boolean(error) && (form.submitCount > 0 || (showError && touched));

        return (
          <InputWrapper>
            <Input
              data-testid={`${name}-input`}
              placeholder=""
              required={required}
              aria-label={name}
              {...inputProps}
              hasError={shouldShowError}
              errorText={error}
              {...field}
              value={inputProps.value ?? field.value}
              onChange={inputProps.onChange ?? field.onChange}
            />
          </InputWrapper>
        );
      }}
    </Field>
  );
}

export default FormikInput;
