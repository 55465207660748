import { Heading, PaginationProps, Paragraph } from '@hexa-ui/components';
import { Info } from '@hexa-ui/icons';
import { useBusinessModelService, useUserMetadata } from 'admin-portal-shared-services';
import CopyButton from 'components/CopyButton/CopyButton';
import { DropdownMenu, DropdownMenuProps } from 'components/DropdownMenu/DropdownMenu';
import { Header } from 'components/Header/Header';
import ImageComponent from 'components/ImageComponent/ImageComponent';
import SearchWithDropdown from 'components/SearchWithDropdown/SearchWithDropdown';
import Table from 'components/Table/Table';
import { SEGMENT_ANALYTICS_APP_NAME, SEGMENT_ANALYTICS_PERMISSION_GROUP } from 'config/constants';
import { AccountTier } from 'domains/AccountTier';
import { useFeatureToggles } from 'hooks/useFeatureToggles';
import { usePermissions } from 'hooks/usePermissions';
import usePreferredLanguage from 'hooks/usePreferredLanguage';
import useTrackingSegment from 'hooks/useTrackingSegment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GetStoreDetailsResponse,
  GetStoreVendorsParams,
  GetStoreVendorsResponse,
  Vendor,
} from 'services/stores';
import {
  AssociateToStoreIcon,
  CompanyDetailsIcon,
  Container,
  DissociateFromStoreIcon,
  HeaderContainer,
  SearchInfo,
  TableContainer,
  TableHeaderContainer,
} from './StoreDetailsPageView.styles';
import { AssociateModal } from './components/AsssociateModal/AssociateModal';
import DisplayNameColumn from './components/DisplayNameColumn/DisplayNameColumn';
import { DissociateFromStoreModal } from './components/DissociateFromStoreModal/DissociateFromStoreModal';
import ManageSettings from './components/ManageSettings/ManageSettings';
import { ShowRecentActivity } from './components/ShowRecentActivity/ShowRecentActivity';
import { StoreHeader } from './components/StoreHeader/StoreHeader';

export interface StoreDetailsPageViewProps {
  store?: GetStoreDetailsResponse;
  vendors: GetStoreVendorsResponse['content'];
  pagination: Partial<PaginationProps> | false;
  isLoading: boolean;
  isFetching: boolean;
  displaySearchInfo: boolean;
  setVendorSearch: React.Dispatch<
    React.SetStateAction<Pick<GetStoreVendorsParams, 'name' | 'vendorId'>>
  >;
}

const DEFAULT_PLACEHOLDER = '-';

const imageCustomRender = (image: string) => <ImageComponent src={image} />;

const vendorIdCustomRender = (id: string, message: string) => (
  <CopyButton text={id} successMessage={message} />
);

const displayNameCustomRender = (name: string, governmentId: string) => (
  <DisplayNameColumn name={name} governmentId={governmentId} />
);

export default function StoreDetailsPageView({
  store,
  vendors,
  isFetching,
  isLoading,
  pagination,
  displaySearchInfo,
  setVendorSearch,
}: StoreDetailsPageViewProps): JSX.Element {
  const { country, id } = useParams<{ country: string; id: string }>();
  const navigate = useNavigate();

  const { formatMessage } = useIntl();
  const analytics = useTrackingSegment();

  const preferredLanguage = usePreferredLanguage();
  const businessModelService = useBusinessModelService();
  const { showManageStores } = useFeatureToggles();
  const { canManageStores } = usePermissions();

  const [dissociateModalOpen, setDissociateModalOpen] = useState(false);

  const SUCCESS_MESSAGE = formatMessage({ id: 'STORE_DETAILS_PAGE.VENDOR_COPIED_NOTIFY' });
  const [isAssociatedModalOpen, setIsAssociatedModalOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);

  const { data } = useUserMetadata();
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const handleOpen = (vendor?: Vendor) => {
    setIsAssociatedModalOpen(true);
    if (vendor) setSelectedVendor(vendor);
  };

  const handleClose = () => setIsAssociatedModalOpen(false);

  const elementClickedEvent = (label: string) => {
    if (selectedVendor === null) return;
    analytics.track('Element Clicked', {
      appDisplayName: SEGMENT_ANALYTICS_APP_NAME,
      isCoreEvent: false,
      pageLabel: 'Store Details',
      pageName: 'Store Details',
      pageUrl: `/company-management/stores/${country}/${id}`,
      userPermissionGroup: data?.authorization?.groups?.filter((value) =>
        value.startsWith(SEGMENT_ANALYTICS_PERMISSION_GROUP)
      ),
      vendorCountry: country,
      vendorDisplayName: selectedVendor?.name,
      vendorId: selectedVendor?.id,
      vendorServiceModel: selectedVendor?.serviceModel,
      vendorTierName: selectedVendor?.tier,
      storeId: store?.id,
      storeCountry: country,
      storeName: store?.name,
      elementLocationType: 'Table Button',
      elementLocationName: 'Table Button',
      elementLabel: label,
      elementName: label,
      elementType: 'BUTTON',
      storeDefaultSeller: store?.defaultVendor?.name,
      storeStatus: store?.status,
    });
  };

  useEffect(() => {
    if (!isAssociatedModalOpen) return;
    elementClickedEvent('Associate with different stores');
  }, [isAssociatedModalOpen]);
  useEffect(() => {
    if (!dissociateModalOpen) return;
    elementClickedEvent('Dissociate from store');
  }, [dissociateModalOpen]);

  const getActions = (vendor: Vendor) => {
    const options: DropdownMenuProps['options'] = [
      ...(canManageStores
        ? [
            {
              icon: AssociateToStoreIcon,
              label: formatMessage({ id: 'STORE_DETAILS_PAGE.DROPDOWN_OPTION_ASSOCIATE' }),
              onClick: () => {
                handleOpen(vendor);
              },
            },
            {
              icon: DissociateFromStoreIcon,
              label: formatMessage({ id: 'STORE_DETAILS_PAGE.DROPDOWN_OPTION_DISSOCIATE' }),
              onClick: () => {
                setDissociateModalOpen(true);
                setSelectedVendor(vendor);
              },
            },
          ]
        : []),
      {
        icon: CompanyDetailsIcon,
        label: formatMessage({ id: 'STORE_DETAILS_PAGE.DROPDOWN_OPTION_DETAILS' }),
        onClick: () => {
          navigate(`/company-management/company-detail/${vendor.id}`);
          elementClickedEvent('Go to company details');
        },
      },
    ];

    return <DropdownMenu options={options} />;
  };

  const getTierLabel = (value?: AccountTier) =>
    formatMessage({
      id: `ACCOUNT_TIER.LABEL_TIER_${value?.toUpperCase()}`,
      defaultMessage: value ?? formatMessage({ id: 'ACCOUNT_TIER.LABEL_TIER_DEFAULT' }),
    });

  const breadcrumbItems = [
    {
      text: formatMessage({ id: 'BREADCRUMB.COMPANY_PROFILE_SETUP' }),
      to: '/company-management',
      isCurrentPage: false,
    },
    {
      text: formatMessage({ id: 'BREADCRUMB.STORES' }),
      to: '/company-management/stores',
      isCurrentPage: false,
    },
    {
      text: formatMessage({ id: 'BREADCRUMB.STORE_DETAILS' }),
      to: `/company-management/stores/${country}/${id}`,
      isCurrentPage: true,
    },
  ];

  const columns = [
    {
      Header: '',
      accessor: 'image',
      disableSortBy: true,
      customRender: imageCustomRender,
      style: {
        width: '80px',
      },
    },
    {
      Header: formatMessage({ id: 'STORE_DETAILS_PAGE.DISPLAY_NAME_COLUMN' }),
      customRender: (_: string, row: Vendor) =>
        displayNameCustomRender(
          row.name ?? DEFAULT_PLACEHOLDER,
          row.governmentId ?? DEFAULT_PLACEHOLDER
        ),
      disableSortBy: true,
    },
    {
      Header: formatMessage({ id: 'STORE_DETAILS_PAGE.VENDOR_ID_COLUMN' }),
      accessor: 'id',
      disableSortBy: true,
      customRender: (vendorId: string) => vendorIdCustomRender(vendorId, SUCCESS_MESSAGE),
    },
    {
      Header: formatMessage({ id: 'STORE_DETAILS_PAGE.SERVICE_MODEL_COLUMN' }),
      accessor: 'serviceModel',
      customRender: (serviceModel: string) =>
        (serviceModel && businessModelService.getBusinessModel(serviceModel, preferredLanguage)) ??
        DEFAULT_PLACEHOLDER,
      disableSortBy: true,
    },
    {
      Header: formatMessage({ id: 'STORE_DETAILS_PAGE.TIER_COLUMN' }),
      accessor: 'tier',
      customRender: (data: AccountTier) => getTierLabel(data),
      disableSortBy: true,
    },
    ...(showManageStores
      ? [
          {
            Header: formatMessage({ id: 'STORE_DETAILS_PAGE.ACTION_COLUMN' }),
            customRender: (_: string, row: Vendor) => getActions(row),
            style: {
              width: '32px',
            },
            disableSortBy: true,
          },
        ]
      : []),
  ];

  const onCloseDissociateModal = () => setDissociateModalOpen(false);

  const searchOptions = [
    {
      key: 'name',
      label: formatMessage({ id: 'STORE_DETAILS_PAGE.VENDOR_SEARCH_DISPLAY_NAME' }),
    },
    {
      key: 'vendorId',
      label: formatMessage({ id: 'STORE_DETAILS_PAGE.VENDOR_SEARCH_VENDOR_ID' }),
    },
  ];

  useEffect(() => {
    if (!searchValue) return;

    analytics.track('Search Applied', {
      appDisplayName: SEGMENT_ANALYTICS_APP_NAME,
      isCoreEvent: false,
      pageLabel: 'Store Details',
      pageName: 'Store Details',
      pageUrl: `/company-management/stores/${country}/${id}`,
      userPermissionGroup: data?.authorization?.groups?.filter((value) =>
        value.startsWith(SEGMENT_ANALYTICS_PERMISSION_GROUP)
      ),
      searchQuery: searchValue,
      numberOfResults: (pagination as PaginationProps).total,
    });
  }, [(pagination as PaginationProps).total]);

  return (
    <Header
      breadcrumbItems={breadcrumbItems}
      actionButtons={
        <>
          {showManageStores && store && (
            <ShowRecentActivity
              storeName={store.name}
              storeDefaultSeller={store.defaultVendor?.name as string}
              storeStatus={store.status}
              storeId={store.id}
              key={`recent-activity-${vendors.length}`}
            />
          )}
          {canManageStores && store && <ManageSettings store={store} />}
        </>
      }
    >
      <HeaderContainer elevated="small" border="medium" data-testid="store-details-header">
        <ImageComponent src={store?.image ?? ''} size="120px" />
        <StoreHeader store={store} />
      </HeaderContainer>
      <TableContainer data-testid="store-details-list">
        <Heading size="H3">
          {formatMessage({ id: 'STORE_DETAILS_PAGE.ASSOCIATED_COMPANIES_TITLE' })}
        </Heading>
        <Paragraph colortype="secondary">
          {formatMessage({ id: 'STORE_DETAILS_PAGE.ASSOCIATED_COMPANIES_SUBTITLE' })}
        </Paragraph>
        <Container elevated="small" border="medium">
          <Table
            header={
              <TableHeaderContainer>
                <SearchWithDropdown
                  options={searchOptions}
                  placeholder={formatMessage({
                    id: 'STORE_DETAILS_PAGE.VENDOR_SEARCH_PLACEHOLDER',
                  })}
                  onChangeSearch={(value, field) => {
                    setSearchValue(value);
                    setVendorSearch((prev) => ({ ...prev, [field]: value }));
                  }}
                />
                {displaySearchInfo && (
                  <SearchInfo size="small">
                    <Info size="medium" />
                    {formatMessage({
                      id: 'STORE_DETAILS_PAGE.VENDOR_SEARCH_INFO',
                    })}
                  </SearchInfo>
                )}
              </TableHeaderContainer>
            }
            isLoading={isLoading || isFetching}
            columns={columns}
            data={vendors}
            loadingMessage={formatMessage({ id: 'STORE_DETAILS_PAGE.LOADING_MESSAGE' })}
            emptyMessage={formatMessage({ id: 'STORE_DETAILS_PAGE.EMPTY_MESSAGE' })}
            pagination={pagination}
          />
        </Container>
      </TableContainer>
      {country && store && (
        <>
          <AssociateModal
            isOpen={isAssociatedModalOpen}
            country={country}
            onClose={handleClose}
            onOpen={handleOpen}
            vendor={selectedVendor}
          />
          <DissociateFromStoreModal
            isOpen={dissociateModalOpen}
            onClose={onCloseDissociateModal}
            data={{
              country,
              store: {
                id: store.id,
                name: store.name,
              },
              vendor: selectedVendor as Vendor,
            }}
          />
        </>
      )}
    </Header>
  );
}
