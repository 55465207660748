import { formatRequestorInfo } from '../../../commonFormatters';
import { ActivityItemType, IntlConfigType } from '../../../format.types';

type EditDefaultStoreSellerParams = {
  intlConfig: IntlConfigType;
  storeName: string;
  defaultSellerName: string;
  userName: string;
  creationDate: Date;
  userId: string;
};

export const editDefaultStoreSeller = ({
  intlConfig,
  storeName,
  defaultSellerName,
  userName,
  creationDate,
  userId,
}: EditDefaultStoreSellerParams): ActivityItemType => {
  const { formatMessage } = intlConfig.intl;

  return {
    title: formatMessage(
      {
        id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_EDIT_DEFAULT_SELLER_TITLE',
      },
      { storeName }
    ),
    description: formatMessage(
      { id: 'STORE_DETAILS_PAGE.RECENT_ACTIVITY_STORE_EDIT_DEFAULT_SELLER_DESCRIPTION' },
      { storeName, defaultSellerName }
    ),
    requestorInfo: formatRequestorInfo(userName, creationDate, intlConfig),
    userId,
  };
};
