import { formatString } from '../commonFormatters';
import { ActionType, ActivityItemType, IntlConfigType, MetadataType } from '../format.types';
import { getAssociateStoreItems } from './associate';
import { getDissociateStoreItems } from './dissociate';
import { getEditStoreItems } from './edit';

type ActivityItemParams = {
  intlConfig: IntlConfigType;
  action: ActionType;
  storeName: string;
  vendorName?: string;
  userName?: string;
  creationDate: Date;
  userId: string;
  fields: MetadataType['fields'];
};

export const buildActionActivityItems = ({
  intlConfig,
  action,
  storeName,
  vendorName,
  userName,
  creationDate,
  fields,
  userId,
}: ActivityItemParams): ActivityItemType[] => {
  const definedVendorName = formatString(vendorName);
  const definedUserName = formatString(userName);

  switch (action) {
    case 'ASSOCIATE':
      return getAssociateStoreItems({
        intlConfig,
        storeName,
        vendorName: definedVendorName,
        userName: definedUserName,
        creationDate,
        userId,
      });
    case 'DISSOCIATE':
      return getDissociateStoreItems({
        intlConfig,
        storeName,
        vendorName: definedVendorName,
        userName: definedUserName,
        creationDate,
        userId,
      });
    case 'EDIT':
      return getEditStoreItems({
        intlConfig,
        storeName,
        vendorName: definedVendorName,
        userName: definedUserName,
        fields,
        creationDate,
        userId,
      });
    default:
      return [];
  }
};
