import { styled } from '@hexa-ui/theme';

export const StyledDialog = styled('div', {
  '& [role="dialog"]': {
    width: 725,
    maxHeight: '90vh',
  },
});

export const DialogContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 600,
});

export const ListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',

  '& > div': {
    paddingRight: '$2',
  },

  '& > div:first-child': {
    marginBottom: '$10',
  },

  '& > div > p': {
    marginBottom: '$2',
    position: 'sticky',
    zIndex: 1,
    inset: 0,
    background: '#fff',
  },
});

export const StoreStatus = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: 'fit-content',
  padding: '4px 12px 4px 24px',
  border: '1px solid #fff',
  borderRadius: '20px',
  background: '#fff',
  height: 26,

  '&:after': {
    content: '""',
    height: 4,
    width: 4,
    borderRadius: '$full',
    position: 'absolute',
    left: 0,
    marginLeft: '12px',
  },

  variants: {
    success: {
      true: {
        color: '#2AA147',
        borderColor: '#CCEFD4',

        '&:after': {
          backgroundColor: '#2AA147',
        },
      },
      false: {
        color: '#C3332B',
        borderColor: '#FFE7E5',

        '&:after': {
          backgroundColor: '#C3332B',
        },
      },
    },
  },
});
