export const tags = {
  getStores: ['getStores'],
  getStoreDetails: ['getStoreDetails'],
  getStoreVendors: ['getStoreVendors'],
  getStoreAvailableVendors: ['getStoreAvailableVendors'],
};

export const getAllTags = () => {
  return Object.values(tags).reduce((acc, tag) => [...acc, ...tag], [] as string[]);
};
