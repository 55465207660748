import { SearchField, Select } from '@hexa-ui/components';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';
import { SearchFieldContainer } from './SearchWithDropdown.styles';

interface SearchWithDropdownOption {
  key: string;
  label: string;
}

export interface SearchWithDropdownProps {
  options: SearchWithDropdownOption[];
  placeholder: string;
  onChangeSearch: (value: string, field: string) => void;
  debounceTime?: number;
}

const SearchWithDropdown = ({
  options,
  placeholder,
  onChangeSearch,
  debounceTime = 700,
}: SearchWithDropdownProps): JSX.Element => {
  const [searchField, setSearchField] = useState(options[0]?.key);
  const [inputValue, setInputValue] = useState('');

  const handleSearchChangeWithDelay = useCallback(
    debounce((value: string, field: string) => onChangeSearch(value, field), debounceTime),
    [debounceTime]
  );

  const handleSanitize = (text: string) => {
    return text.replace(/[*?(){}[\]+\\]/g, '');
  };
  useEffect(() => {
    return () => {
      handleSearchChangeWithDelay.cancel();
    };
  }, [handleSearchChangeWithDelay]);

  return (
    <SearchFieldContainer>
      <SearchField.Root
        data-testid="search-field-with-dropdown"
        value={inputValue}
        width={560}
        onChange={(event) => {
          const { value } = event.target as HTMLInputElement;
          const sanitizedValue = handleSanitize(value);
          setInputValue(sanitizedValue);
          handleSearchChangeWithDelay(sanitizedValue.trim(), searchField);
        }}
        onClear={() => {
          setInputValue('');
          onChangeSearch('', searchField);
        }}
        placeholder={placeholder}
      >
        <Select.Root
          data-testid="search-field-dropdown"
          size="small"
          shape="pill"
          width="max-content"
          value={searchField}
          onChange={(selectedSearchField) => {
            setSearchField(selectedSearchField);
            setInputValue('');
            options.forEach(({ key }) => {
              onChangeSearch('', key);
            });
          }}
        >
          {options.map(({ key, label }, index) => {
            return (
              <Select.Option data-testid={`search-field-option-${index}`} key={key} value={key}>
                {label}
              </Select.Option>
            );
          })}
        </Select.Root>
      </SearchField.Root>
    </SearchFieldContainer>
  );
};

export default SearchWithDropdown;
