import { styled } from 'theme';

export const SearchFieldContainer = styled('div', {
  '& div': {
    '& input': {
      fontSize: '$3',
    },
    '& button': {
      margin: 0,
    },
  },
});
