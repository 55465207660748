import { createTheme } from '@material-ui/core';
import { overrides, palette, typography } from './material';

export {
  config,
  configureStitches,
  createTheme,
  css,
  getCssText,
  globalCss,
  keyframes,
  styled,
  theme,
} from './stitches';

export const muiTheme = createTheme({
  palette,
  typography,
  overrides,
});
