/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { Button, Dialog, Heading, LoadingDots, Paragraph } from '@hexa-ui/components';
import { useQueryClient } from '@tanstack/react-query';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import ImageComponent from 'components/ImageComponent/ImageComponent';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { emptySplitApi } from 'services/baseApi';
import { tags } from 'services/tags';
import { SelectedStore } from '../../AssociateModal';
import { StoreCard, StoreInfo, StoreList, WrapperButtons } from '../../AssociateModal.styles';
import getModalStates from '../../utils/getModalStates';
import { DialogContainer, ListContainer, StoreStatus, StyledDialog } from './ConfirmModal.styles';

export interface ConfirmModalParams {
  isOpen: boolean;
  isLoading: boolean;
  country: string;
  stores: SelectedStore[];
  vendorName: string;
  onConfirm: () => void;
  onClose: () => void;
  onGoback: () => void;
}

export function ConfirmModal({
  isOpen,
  stores,
  country,
  isLoading,
  vendorName,
  onClose,
  onConfirm,
  onGoback,
}: Readonly<ConfirmModalParams>) {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [errorCount, isCompleted] = useMemo(
    () => [
      stores.filter((store) => store.isError).length,
      stores.every((store) => store.isSuccess),
    ],
    [stores]
  );

  const handleClose = () => {
    queryClient.invalidateQueries(['get-stores-by-vendor-id']);
    dispatch(emptySplitApi.util.invalidateTags(tags.getStoreVendors));
    onClose();
  };

  const handleBackButton = useCallback(() => {
    return !!errorCount || isCompleted ? handleClose() : onGoback();
  }, [isLoading, errorCount, isCompleted]);

  const [MODAL_TITLE, MODAL_DESCRIPTION, BACK_BUTTON, CONFIRM_BUTTON] = getModalStates({
    errorCount,
    isCompleted,
    isLoading,
    storesCount: stores.length,
  });

  const handleConfirm = () => {
    if (isCompleted) {
      handleClose();

      return toast.notify({
        message: formatMessage(
          { id: 'STORE_DETAILS_PAGE.SUCCESS_NOTIFY_MESSAGE' },
          { company: vendorName }
        ),
        type: TypeToast.SUCCESS,
        actions: [
          {
            name: formatMessage({ id: 'STORE_DETAILS_PAGE.SUCCESS_NOTIFY_BUTTON' }),
            action: () =>
              navigate(`/company-management/stores/${country.toLocaleUpperCase().trim()}`),
          },
        ],
      });
    }

    onConfirm();
  };

  const renderActionButtons = useCallback(
    () => (
      <WrapperButtons>
        {!isCompleted && !isLoading && (
          <Button size="medium" variant="secondary" onClick={handleBackButton} disabled={isLoading}>
            {formatMessage({ id: BACK_BUTTON })}
          </Button>
        )}
        <Button size="medium" variant="primary" onClick={handleConfirm} isLoading={isLoading}>
          {formatMessage({ id: CONFIRM_BUTTON })}
        </Button>
      </WrapperButtons>
    ),
    [handleConfirm, isLoading, handleBackButton, BACK_BUTTON, CONFIRM_BUTTON, isCompleted]
  );

  const fallbackComponent = useMemo(
    () => (
      <Paragraph colortype="secondary">
        {formatMessage({ id: 'STORE_DETAILS_PAGE.NO_STORES_SELECTED' })}
      </Paragraph>
    ),
    []
  );

  const renderStoreStatus = (store: SelectedStore) => {
    const { isError, isLoading, isSuccess } = store;

    if (!isSuccess && !isError && !isLoading) return null;

    if (isLoading) return <LoadingDots data-testid="loader" />;

    return (
      <StoreStatus success={isSuccess}>
        {isSuccess
          ? formatMessage({ id: 'STORE_DETAILS_PAGE.STORE_SUCCESS_STATUS' })
          : formatMessage({ id: 'STORE_DETAILS_PAGE.STORE_FAILED_STATUS' })}
      </StoreStatus>
    );
  };

  const renderedStores = useCallback(
    (associated: boolean): JSX.Element[] | JSX.Element => {
      const renderedStores = stores
        .filter((store) => store?.associated === associated)
        .map((store) => (
          <StoreCard key={store?.id} data-testid="store-card">
            <ImageComponent src={store?.image!} size="64px" />
            <StoreInfo>
              <Paragraph weight="semibold">{store?.name}</Paragraph>
              {renderStoreStatus(store)}
            </StoreInfo>
          </StoreCard>
        ));

      return renderedStores.length ? renderedStores : fallbackComponent;
    },
    [stores]
  );

  return (
    <StyledDialog>
      <Dialog.Root
        title={<Heading size="H2">{formatMessage({ id: MODAL_TITLE })}</Heading>}
        open={isOpen}
        onClose={isCompleted ? handleConfirm : handleClose}
        closeButton={!isLoading}
        actions={renderActionButtons()}
      >
        <DialogContainer>
          <Paragraph css={{ marginBottom: '32px' }}>
            {formatMessage({ id: MODAL_DESCRIPTION }, { vendorName })}
          </Paragraph>

          <ListContainer style={{ height: '100%' }}>
            <div>
              <Paragraph weight="semibold">
                {formatMessage({ id: 'STORE_DETAILS_PAGE.STORES_TO_ASSOCIATE' })}
              </Paragraph>

              <StoreList>{renderedStores(true)}</StoreList>
            </div>

            <div>
              <Paragraph weight="semibold">
                {formatMessage({ id: 'STORE_DETAILS_PAGE.STORES_TO_DISSOCIATE' })}
              </Paragraph>

              <StoreList>{renderedStores(false)}</StoreList>
            </div>
          </ListContainer>
        </DialogContainer>
      </Dialog.Root>
    </StyledDialog>
  );
}
