import errorImg from 'assets/error-img-buzz.svg';
import { useState } from 'react';
import { StyledImage } from './ImageComponent.styles';

interface ImageComponentProps {
  src: string;
  size?: string;
}

export default function ImageComponent({ src, size }: ImageComponentProps): JSX.Element {
  const [isError, setIsError] = useState(false);

  if (!src || isError) {
    return <StyledImage src={errorImg} css={{ width: size, height: size }} />;
  }

  return (
    <StyledImage
      src={src}
      onError={() => setIsError(true)}
      draggable={false}
      css={{ width: size, height: size }}
    />
  );
}
