import { Toggle as HexaToggle, Paragraph } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import { Container } from './Toggle.styles';

interface ToggleProps {
  checked: boolean;
  disabled?: boolean;
  onChange?: () => void;
}

const Toggle = ({ checked, disabled, onChange }: ToggleProps) => {
  const { formatMessage } = useIntl();

  const ACTIVE_LABEL = formatMessage({ id: 'COMPONENTS.TOGGLE_ACTIVE_LABEL' });
  const INACTIVE_LABEL = formatMessage({ id: 'COMPONENTS.TOGGLE_INACTIVE_LABEL' });

  return (
    <Container disabled={disabled}>
      <HexaToggle checked={checked} onClick={onChange} disabled={disabled} />
      <Paragraph>{checked ? ACTIVE_LABEL : INACTIVE_LABEL}</Paragraph>
    </Container>
  );
};

export default Toggle;
