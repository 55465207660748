import {
  createFeatureToggleServiceV2,
  useFeatureToggleServiceV2 as featureToggleServiceV2,
} from 'admin-portal-shared-services';
import FeatureToggleV2 from 'admin-portal-shared-services/dist/services/featureToggle/model/FeatureToggleV2';
import { OPTIMIZELY_PROJECT_NAME } from 'config/constants';
import { useEffect, useState } from 'react';

type UseInitializeFeatureToggleResult = {
  isReady: boolean;
};

const isFeatureToggleServiceLoaded = () =>
  (window.services?.FeatureToggleOptimizelyV2 as FeatureToggleV2)?.[OPTIMIZELY_PROJECT_NAME];

export const useInitializeFeatureToggles = (
  optimizelyKey: string
): UseInitializeFeatureToggleResult => {
  const [isReady, setIsReady] = useState<boolean>(false);

  const handleIsReady = (): void => {
    const toggleService = featureToggleServiceV2(OPTIMIZELY_PROJECT_NAME);
    if (toggleService.getIsReady()) {
      setIsReady(true);
      return;
    }
    toggleService.onReady().then(() => {
      setIsReady(true);
    });
  };

  useEffect(() => {
    if (isFeatureToggleServiceLoaded()) {
      handleIsReady();
      return;
    }

    createFeatureToggleServiceV2(OPTIMIZELY_PROJECT_NAME, optimizelyKey);
    handleIsReady();
  }, [optimizelyKey]);

  return {
    isReady,
  };
};
