import { IconButton as HexaIconButton } from '@hexa-ui/components';
import { styled } from 'theme';

export const ButtonGrid = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '$4',
  '& > button': {
    margin: 0,
  },
});

export const IconButton = styled(HexaIconButton, {
  marginLeft: 'auto',
});
