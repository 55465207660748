import { useQuery } from '@tanstack/react-query';
import Api from 'Api/Api';
import { useMemo } from 'react';
import { GetStoresParams, GetStoresResponse, getStoresQuery } from 'services/stores';

const getStores = async (params: GetStoresParams) => {
  const { url, ...config } = getStoresQuery(params);

  const result = await Api.get<GetStoresResponse>({ url, config });

  return result.data;
};

export interface UseGetStoresByVendorIdResult {
  isFetching: boolean;
  isLoading: boolean;
  isError: boolean;
  error?: unknown;
  data?: GetStoresResponse['content'];
}

export interface UseGetStoresByVendorIdParams {
  country?: string;
  vendorId?: string;
}

const MAX_PAGE_SIZE = 500;
const FIRST_PAGE = 0;

export const useGetStoresByVendorId = ({
  country,
  vendorId,
}: UseGetStoresByVendorIdParams): UseGetStoresByVendorIdResult => {
  const { isLoading, isError, error, data, isFetching } = useQuery({
    queryKey: ['get-stores-by-vendor-id', country, vendorId],
    queryFn: () =>
      getStores({
        country: country!,
        page: FIRST_PAGE,
        pageSize: MAX_PAGE_SIZE,
        vendorId,
      }),
    refetchOnWindowFocus: false,
    enabled: !!country && !!vendorId,
  });

  return useMemo(
    () => ({
      isFetching,
      isLoading,
      isError,
      error,
      data: data?.content,
    }),
    [isFetching, isLoading, isError, error, data?.content]
  );
};
