import { Paragraph } from '@hexa-ui/components';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { Container, CopyIcon, CopySection } from './CopyButton.styles';

export interface CopyButtonProps {
  text: string;
  color?: 'primary' | 'secondary';
  successMessage?: string;
  size?: 'large' | 'medium' | 'tiny' | 'xlarge';
  clipBoardText?: string;
}

function CopyButton({
  text,
  color = 'primary',
  successMessage,
  size = 'large',
  clipBoardText = text,
}: CopyButtonProps): JSX.Element {
  const toastService = useToast();

  const onCopyText = () => {
    navigator.clipboard.writeText(clipBoardText);

    if (successMessage) {
      toastService.notify({
        message: successMessage,
        type: TypeToast.SUCCESS,
      });
    }
  };

  return (
    <Container>
      <CopySection
        data-testid="copy-button"
        onClick={(e) => {
          onCopyText();
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Paragraph size="small" colortype={color}>
          {text}
        </Paragraph>
        <CopyIcon size={size} />
      </CopySection>
    </Container>
  );
}

export default CopyButton;
