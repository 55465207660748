import { Card, Paragraph } from '@hexa-ui/components';
import { Store2Edit, Unlink, ZoomIn } from '@hexa-ui/icons';
import { styled } from '../../theme';

export const HeaderContainer = styled(Card, {
  '&&': {
    display: 'flex',
    flexDirection: 'row',
  },
  alignItems: 'center',
  gap: '$6',
  padding: '$6',
  marginBottom: '$8',
});

export const StoreDetails = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
});

export const Container = styled(Card, {
  padding: '0 0 $4',
  marginBottom: '$8',
});

export const TableContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
});

export const TableHeaderContainer = styled('div', {
  display: 'flex',
  gap: '$2',
  flexWrap: 'wrap',
});

export const SearchInfo = styled(Paragraph, {
  display: 'flex',
  gap: '$1',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 $2',
});

export const AssociateToStoreIcon = () => <Store2Edit />;

export const DissociateFromStoreIcon = () => <Unlink />;

export const CompanyDetailsIcon = () => <ZoomIn />;
