import { Table as BaseTable, TableProps as BaseTableProps } from '@hexa-ui/components';
import React from 'react';
import Styled from './Table.styles';

export interface Column<T> {
  Header: React.ReactNode | string;
  accessor: string;
  disableSortBy: boolean;
  customRender?: (image: string) => JSX.Element;
  Cell?: (props: { value: string; row: { original: T } }) => JSX.Element;
  style?: {
    width: string;
  };
}

export interface TableProps<T> extends Omit<BaseTableProps<T>, 'loading'> {
  header?: React.ReactNode;
  isLoading?: boolean;
}

function Table<T>({
  header,
  columns,
  data = [],
  isLoading = false,
  loadingMessage = '',
  emptyMessage = '',
  ...tableProps
}: Readonly<TableProps<T>>): JSX.Element {
  return (
    <Styled.TableWrapper>
      {header && <Styled.Header>{header}</Styled.Header>}
      <BaseTable
        {...tableProps}
        data={data}
        columns={columns}
        loading={isLoading}
        loadingMessage={loadingMessage}
        emptyMessage={emptyMessage}
      />
    </Styled.TableWrapper>
  );
}

export default Table;
