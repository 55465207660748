import { store } from 'app/store';
import { Countries } from 'domains/Countries';
import { EndpointsToLog, LogData, startLog } from 'features/log/log';
import get from 'lodash/get';
import set from 'lodash/set';
import { UpdateVendorStoresParams } from 'services/company';
import { UpdateStoreParams } from 'services/stores';

interface LogUpdateVendorStoresEndpoint {
  endpoint: EndpointsToLog.updateVendorStores;
  data: UpdateVendorStoresParams['stores'];
  extraData: UpdateVendorStoresParams['logData'];
}

interface LogUpdateStoreEndpoint {
  endpoint: EndpointsToLog.updateStore;
  data: Omit<UpdateStoreParams, 'logData'>;
  extraData: UpdateStoreParams['logData'];
}

const endpointMapper = {
  [EndpointsToLog.updateVendorStores]: (
    payload: LogUpdateVendorStoresEndpoint['data'],
    extra: LogUpdateVendorStoresEndpoint['extraData']
  ) => ({
    stores: payload,
    vendorId: extra.vendorId,
    vendorName: extra.vendorName,
  }),
  [EndpointsToLog.updateStore]: (
    payload: LogUpdateStoreEndpoint['data'],
    extra: LogUpdateStoreEndpoint['extraData']
  ) => ({
    storeId: extra.storeId,
    fields: extra.changedFields.reduce((acc, path) => {
      const value = get(payload, path);
      if (value !== undefined) {
        if (path === 'defaultVendorId') {
          set(acc, 'defaultVendor', {
            id: value,
            name: extra.defaultVendorName,
          });
          return acc;
        }
        set(acc, path, value);
      }
      return acc;
    }, {}),
  }),
} as {
  [key in EndpointsToLog]: (payload: unknown, extra: unknown) => LogData['data'];
};

export const logDataByEndpoint = ({
  endpoint,
  data,
  extraData,
  country,
}: (LogUpdateVendorStoresEndpoint | LogUpdateStoreEndpoint) & { country: Countries }) => {
  const endpointData = endpointMapper[endpoint]?.(data, extraData);

  if (endpointData) {
    store.dispatch(startLog({ endpoint, country, data: endpointData } as LogData));
  }
};
