import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { initialAxios as axios } from 'Api/Api';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { API_BASE_PATH } from 'config/constants';
import { removeEmptyValuesFromObject } from 'utils';
import {
  UpdateVendorStoresParams,
  UpdateVendorStoresResponse,
  updateVendorStoresMutation,
} from './company';
import {
  GetStoreAvailableVendorsParams,
  GetStoreAvailableVendorsResponse,
  GetStoreDetailsParams,
  GetStoreDetailsResponse,
  GetStoreVendorsParams,
  GetStoreVendorsResponse,
  GetStoresParams,
  GetStoresResponse,
  UpdateStoreParams,
  UpdateStoreResponse,
  getStoreAvailableVendorsQuery,
  getStoreDetailsQuery,
  getStoreVendorsQuery,
  getStoresQuery,
  updateStoreMutation,
} from './stores';
import { getAllTags, tags } from './tags';

export interface BaseQueryFnArgs {
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
}

const getResult = async (
  baseUrl: string,
  url: string,
  method: AxiosRequestConfig['method'],
  data: AxiosRequestConfig['data'],
  params: AxiosRequestConfig['params'],
  headers: AxiosRequestConfig['headers']
) => {
  try {
    const result = await axios({
      url: baseUrl + url,
      method,
      data,
      params: removeEmptyValuesFromObject(params),
      headers,
    });

    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data,
      },
    };
  }
};

export const axiosBaseQuery =
  ({ baseUrl }: { baseUrl: string }): BaseQueryFn<BaseQueryFnArgs> =>
  async ({ url, method, data, params, headers }) => {
    return getResult(baseUrl, url, method, data, params, headers);
  };

export const emptySplitApi = createApi({
  reducerPath: 'api',
  tagTypes: getAllTags(),
  baseQuery: axiosBaseQuery({ baseUrl: API_BASE_PATH }),
  endpoints: (builder) => ({
    getStores: builder.query<GetStoresResponse, GetStoresParams>({
      query: getStoresQuery,
      providesTags: tags.getStores,
    }),
    getStoreDetails: builder.query<GetStoreDetailsResponse, GetStoreDetailsParams>({
      query: getStoreDetailsQuery,
      providesTags: tags.getStoreDetails,
    }),
    getStoreVendors: builder.query<GetStoreVendorsResponse, GetStoreVendorsParams>({
      query: getStoreVendorsQuery,
      providesTags: tags.getStoreVendors,
    }),
    getStoreAvailableVendors: builder.query<
      GetStoreAvailableVendorsResponse,
      GetStoreAvailableVendorsParams
    >({
      query: getStoreAvailableVendorsQuery,
      providesTags: tags.getStoreAvailableVendors,
    }),
    updateVendorStores: builder.mutation<UpdateVendorStoresResponse, UpdateVendorStoresParams>({
      query: updateVendorStoresMutation,
      invalidatesTags: (_result, error) => (error ? [] : tags.getStoreVendors),
    }),
    updateStore: builder.mutation<UpdateStoreResponse, UpdateStoreParams>({
      query: updateStoreMutation,
      invalidatesTags: (_result, error) => (error ? [] : tags.getStoreDetails),
    }),
  }),
});

export const {
  useGetStoresQuery,
  useGetStoreDetailsQuery,
  useGetStoreVendorsQuery,
  useGetStoreAvailableVendorsQuery,
  useUpdateVendorStoresMutation,
  useUpdateStoreMutation,
} = emptySplitApi;
