import { Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const WrapperButtons = styled('div', {
  display: 'flex',
  gap: '16px',
  justifyContent: 'flex-end',
});

export const Body = styled(Paragraph, {
  marginBottom: '32px',
});

export const CompanyCard = styled('div', { display: 'flex', gap: '24px', marginBottom: '32px' });

export const CompanyInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const StoreList = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '16px',
});

export const StoreCard = styled('div', {
  display: 'flex',
  padding: '16px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '24px',
  backgroundColor: '#FAFAFA',
  borderRadius: '8px',
});

export const StoreInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',

  '& > div:last-child': {
    minHeight: 26,
  },
});

export const StoreStatus = styled('div', {
  color: 'green',
  fontSize: '14px',
});
