import { RefCallback, useCallback, useEffect, useState } from 'react';

export interface UseIsVisibleReturn {
  isVisible: boolean;
  ref: RefCallback<HTMLElement>;
}

export function useIsVisible(): UseIsVisibleReturn {
  const [isVisible, setIsVisible] = useState(false);
  const [refElement, setRefElement] = useState<HTMLElement | null>(null);

  const setRef = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setRefElement(node);
    }
  }, []);

  useEffect(() => {
    if (refElement) {
      const observer = new IntersectionObserver(([entry]) => {
        setIsVisible(entry.isIntersecting);
      });
      observer.observe(refElement);

      return () => {
        observer.disconnect();
      };
    }
  }, [refElement]);

  return { isVisible, ref: setRef };
}
