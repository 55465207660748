import { Paragraph } from '@hexa-ui/components';

interface DisplayNameColumnProps {
  name: string;
  governmentId: string;
}

export const DisplayNameColumn = ({ name, governmentId }: DisplayNameColumnProps) => {
  return (
    <>
      <Paragraph size="small" weight="medium">
        {name}
      </Paragraph>
      <Paragraph size="small">{governmentId}</Paragraph>
    </>
  );
};

export default DisplayNameColumn;
