import { config as hexaConfig } from '@hexa-ui/theme';
import { createStitches } from '@stitches/react';
import { ConfigType } from '@stitches/react/types/config';

const stitchesOptions = {
  prefix: 'one-store-management',
  theme: hexaConfig.theme as unknown as ConfigType.Theme<typeof hexaConfig.theme>,
  media: hexaConfig.media,
};

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config } =
  createStitches(stitchesOptions);

export const configureStitches = (): void => {
  createStitches(stitchesOptions);
};
